import {
    Button, Caption1, Card, CardFooter, CardHeader, CardPreview, Dialog, DialogSurface,
    Link, makeStyles, Text,
    Tooltip
} from "@fluentui/react-components";
import {
    AlbumRegular, bundleIcon, CartFilled, CartRegular, ContentViewGalleryLightningRegular,
    HeartPulseRegular, LockClosedKeyFilled, LockClosedKeyRegular, PersonFeedbackRegular
} from "@fluentui/react-icons";
import { Providers } from "@microsoft/mgt-element";
import { lazy, ReactElement, startTransition, useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import JToolContext from "../Components/JToolContext";
import PageTitle from "../Components/PageTitle";
import useIsSignedIn from "../Hooks/useIsSignedIn";

const useClasses = makeStyles({
    LoginButton: {
        marginTop: "60px",
        marginBottom: "60px",
        alignSelf: "start"
    },
    HomeCardIndent: {
        paddingInlineStart: "16px"
    },
    HomeCardFooter: {
        justifyContent: "flex-end"
    }
});

const FirstRunCarousel = lazy(() => import("../Components/FirstRunCarousel"));

export default function HomePage(props: { loading?: boolean }): ReactElement {
    const { isSignedIn } = useIsSignedIn();
    const { tenant } = useContext(JToolContext);
    const navigator = useNavigate();
    const Key = bundleIcon(LockClosedKeyFilled, LockClosedKeyRegular);
    const CartIcon = bundleIcon(CartFilled, CartRegular);
    const [firstRun, setFirstRun] = useState<boolean>(JSON.parse(localStorage.getItem("firstRun") ?? "true"));
    const styles = useClasses();

    const navigate = (url: string) => {
        startTransition(() => { navigator(url) });
    };

    if (!isSignedIn && !props.loading) return (<section>
        <div className='stack'>
            <PageTitle title="Jisc Cloud Assist" />
            <Text>
                This service is currently early in the Jisc product lifecycle. <br />
                Access is currently limited to a small number of people.<br />
                If you would like to participate in future testing of this tool, please contact us at <Link href="mailto:innovation@jisc.ac.uk">innovation@jisc.ac.uk</Link>
            </Text>
            <Dialog open={firstRun} onOpenChange={(e, data) => setFirstRun(data.open)}>
                <DialogSurface>
                    <FirstRunCarousel setFirstRun={setFirstRun} />
                </DialogSurface>
            </Dialog>
            <div className="stack hoz">
                {!firstRun && <Tooltip content="If this is your first time using Cloud Assist please follow the first time wizard before logging in" relationship="description">
                    <Button className={styles.LoginButton} appearance='primary' icon={<Key />} size="large" iconPosition="after" onClick={() => Providers.globalProvider.login ? Providers.globalProvider.login() : undefined}>Login</Button>
                </Tooltip>}
                {firstRun && <Tooltip content="First time wizard" relationship="description">
                    <Button className={styles.LoginButton} appearance='primary' size="large" iconPosition="after" onClick={() => setFirstRun(true)}>First time wizard</Button>
                </Tooltip>}
            </div>
        </div>
    </section>);

    const makeLink = (url: string) =>
        (new Date(tenant?.renewalDate ?? new Date()) > new Date() || tenant?.isPreview || tenant?.isReadonly) && tenant?.hasCe ? url : "https://www.jisc.ac.uk/cloud/assist-service";

    const navLink = (url: string) => {
        if ((new Date(tenant?.renewalDate ?? new Date()) > new Date() || tenant?.isPreview || tenant?.isReadonly) && tenant?.hasCe) navigate(url);
        else window.open("https://www.jisc.ac.uk/cloud/assist", "_blank");
    };

    return (<section>
        <div className='stack'>
            <PageTitle title="Jisc Cloud Assist" subtitle="How can we help you today?" />
            <div className="stack cards">
                <Link href={makeLink("/reports")} appearance='subtle' onClick={(e) => e.preventDefault()}>
                    <Card size='large' orientation="horizontal" onSelectionChange={(e, d) => d.selected && navLink("reports")}>
                        <CardPreview>
                            <AlbumRegular />
                        </CardPreview>
                        <CardHeader header={<Text as="h4">Run Device Reports</Text>}
                            description={<ul className={styles.HomeCardIndent}>
                                <li><Caption1>See which devices are connecting to your tenant</Caption1></li>
                                <li><Caption1>Find out of date devices</Caption1></li>
                                <li><Caption1>Generate Device summary reports</Caption1></li>
                            </ul>} />
                        {!tenant?.hasCe && <Tooltip content="Subscribe to the assist service automaton for this feature" relationship="description">
                            <CardFooter className={styles.HomeCardFooter}><Button onClick={() => navLink("reports")} appearance="primary" icon={<CartIcon />}>Subscribe</Button></CardFooter>
                        </Tooltip>}
                    </Card>
                </Link>
                <Link href={makeLink("/automations")} appearance='subtle' onClick={(e) => e.preventDefault()}>
                    <Card size='large' orientation="horizontal" onSelectionChange={(e, d) => d.selected && navLink("automations")}>
                        <CardPreview>
                            <ContentViewGalleryLightningRegular />
                        </CardPreview>
                        <CardHeader header={<Text as="h4">Configure your Device automations</Text>}
                            description={<Caption1>Automatic updates to your policies and Entra devices, on a schedule set by you</Caption1>} />
                        {!tenant?.hasCe && <Tooltip content="Subscribe to the assist service automaton for this feature" relationship="description">
                            <CardFooter className={styles.HomeCardFooter}><Button onClick={() => navLink("reports")} appearance="primary" icon={<CartIcon />}>Subscribe</Button></CardFooter>
                        </Tooltip>}
                    </Card>
                </Link>
                <Link href="/health" appearance='subtle' onClick={(e) => e.preventDefault()}>
                    <Card size='large' orientation="horizontal" onSelectionChange={(e, d) => d.selected && navigate("health")}>
                        <CardPreview>
                            <HeartPulseRegular />
                        </CardPreview>
                        <CardHeader header={<Text as="h4">Run a 365 Health check</Text>}
                            description={<Caption1>Check your tenant against our recommendations</Caption1>} />
                    </Card>
                </Link>
                <Link href="/feedback" appearance='subtle' onClick={(e) => e.preventDefault()}>
                    <Card size='large' orientation="horizontal" appearance='outline' onSelectionChange={(e, d) => d.selected && navigate("feedback")}>
                        <CardPreview>
                            <PersonFeedbackRegular />
                        </CardPreview>
                        <CardHeader header={<Text weight="semibold">Give feedback</Text>}
                            description={<Caption1>Let us know any issues or thoughts you’ve had while testing this app</Caption1>} />
                    </Card>
                </Link>
            </div>
        </div>
    </section>);
}