export interface IPalette { 
    primary: string;
    tint20: string;
    tint40: string;
    tint60: string;
    tint80: string;
    accent1: string;
    accent2: string;
};

export interface JiscThemeTokens {
    VividPink: IPalette,
    Pink: IPalette,
    Grape: IPalette,
    Purple: IPalette,
    Navy: IPalette,
    Blue: IPalette,
    Jade: IPalette,
    Green: IPalette,
    Yellow: IPalette
}

const VividPink: IPalette = {
    primary: "#E62645",
    tint20: "#FAD3D4",
    tint40: "#F5ABAF",
    tint60: "#F0858A",
    tint80: "#EB5A67",
    accent1: "#A4123E",
    accent2: "#691B32"
};

const Pink: IPalette = {
    primary: "#CE0F69",
    tint20: "#FAD5DD",
    tint40: "#F5AFC0",
    tint60: "#ED85A0",
    tint80: "#E35782",
    accent1: "#EF80B1",
    accent2: "#7F2246"
};

const Grape: IPalette = {
    primary: "#8E1558",
    tint20: "#EBD1D9",
    tint40: "#D6A5B9",
    tint60: "#BE7594",
    tint80: "#A74977",
    accent1: "#AE1B57",
    accent2: "#682045"
};

const Purple: IPalette = {
    primary: "#6D2077",
    tint20: "#E5D0E1",
    tint40: "#C9A6C9",
    tint60: "#A879AC",
    tint80: "#8B4F91",
    accent1: "#4B2770",
    accent2: "#3E2B56"
};

const Navy: IPalette = {
    primary: "#0D224C",
    tint20: "#CBCEDA",
    tint40: "#99A0B7",
    tint60: "#697496",
    tint80: "#384973",
    accent1: "#7E9CC1",
    accent2: "#193B69"
};

const Blue: IPalette = {
    primary: "#007DBA",
    tint20: "#D0E1EF",
    tint40: "#A4C8E4",
    tint60: "#72ACD5",
    tint80: "#2F96C9",
    accent1: "#4CC0DF",
    accent2: "#003D50"
};

const Jade: IPalette = {
    primary: "#00857D",
    tint20: "#C6E4E1",
    tint40: "#8CCDC6",
    tint60: "#30B5AC",
    tint80: "#009C94",
    accent1: "#00AA88",
    accent2: "#004438"
};

const Green: IPalette = {
    primary: "#006938",
    tint20: "#D0E0D2",
    tint40: "#A1C1AB",
    tint60: "#73A381",
    tint80: "#41865C",
    accent1: "#4D9D34",
    accent2: "#22372B"
};

const Yellow: IPalette = {
    primary: "#F8A800",
    tint20: "#FEEACF",
    tint40: "#FED9A2",
    tint60: "#FCC975",
    tint80: "#FAB63F",
    accent1: "#B58410",
    accent2: "#75541C"
};

export const Tokens: JiscThemeTokens = {
    VividPink,
    Pink,
    Grape,
    Purple,
    Navy,
    Blue,
    Jade,
    Green,
    Yellow
};