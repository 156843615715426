import { BrandVariants, createDarkTheme, createLightTheme, Theme } from "@fluentui/react-components";
import { useEffect, useState } from "react";
import { JiscThemeTokens, Tokens } from "../Interfaces";

interface JToolTheme {
    isDark: boolean;
    theme: Theme;
    tokens: JiscThemeTokens;
}

const JiscTheme: BrandVariants = { 
    10: "#020404",
    20: "#132E2C",
    30: "#153C38",
    40: "#154A46",
    50: "#145853",
    60: "#116761",
    70: "#0B766F",
    80: Tokens.Jade.primary,
    90: "#05867E",
    100: Tokens.Jade.tint80,
    110: "#55A098",
    120: Tokens.Jade.tint60,
    130: "#86BAB4",
    140: Tokens.Jade.tint40,
    150: "#B5D5D1",
    160: Tokens.Jade.tint20
};

const lightTheme: Theme = {
    ...createLightTheme(JiscTheme),
    fontFamilyBase: 'Roboto, system-ui, "Apple Color Emoji", "Segoe UI Emoji", sans-serif;'
};

const darkTheme: Theme = {
    ...createDarkTheme(JiscTheme),
    fontFamilyBase: 'Roboto, system-ui, "Apple Color Emoji", "Segoe UI Emoji", sans-serif;'
};

const useTheme = (): JToolTheme => {
    const [theme, setTheme] = useState<Theme>(window.matchMedia("(prefers-color-scheme: dark)").matches ? darkTheme : lightTheme);
    const [isDark, setIsDark] = useState<boolean>(window.matchMedia("(prefers-color-scheme: dark)").matches);
    useEffect(() => {
        window.matchMedia("(prefers-color-scheme: dark)").addEventListener("change", (e) => {
            if (e.matches) {
                setTheme(darkTheme);
                setIsDark(true);
            }
        });
        window.matchMedia("(prefers-color-scheme: light)").addEventListener("change", (e) => {
            if (e.matches) {
                setTheme(lightTheme);
                setIsDark(false);
            }
        });
    }, []);
    return { isDark, theme, tokens: Tokens };
};

export default useTheme;