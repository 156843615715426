import {
    Badge, Button, ButtonProps, FluentProvider, MessageBar, MessageBarActions, MessageBarBody,
    MessageBarGroup, MessageBarTitle, ProgressBar, Subtitle2Stronger, Toaster, useId
} from "@fluentui/react-components";
import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import { Login, Providers, ThemeToggle } from "@microsoft/mgt-react";
import TimeAgo from "javascript-time-ago";
import en from "javascript-time-ago/locale/en";
import engb from "javascript-time-ago/locale/en-GB";
import { lazy, ReactElement, useEffect, useState, useTransition } from "react";
import { Route, Routes } from "react-router-dom";
import "./App.css";
import { JTool } from "./Components/JTool";
import JToolContext from "./Components/JToolContext";
import Nav from "./Components/Nav";
import useIsSignedIn from "./Hooks/useIsSignedIn";
import useTheme from "./Hooks/useTheme";
import { LogType } from "./Interfaces";
import logo from "./logo.svg";
import Admin from "./Pages/Admin";
import HomePage from "./Pages/HomePage";

TimeAgo.addDefaultLocale(engb);
TimeAgo.addDefaultLocale(en);

const Tenants = lazy(() => import("./Pages/AutomationPage"));
const Logs = lazy(() => import("./Pages/LogsPage"));
const HealthCheck = lazy(() => import("./Pages/HealthCheckPage"));
const Reports = lazy(() => import("./Pages/ReportsPage"));
const Permissions = lazy(() => import("./Pages/PermissionsPage"));
const About = lazy(() => import("./Pages/AboutPage"));
const Database = lazy(() => import("./Pages/DatabasePage"));
const Consent = lazy(() => import("./Pages/ConsentPage"));
const AdminHealthChecks = lazy(() => import("./Pages/Admin/HealthChecks"));
const AdminTenants = lazy(() => import("./Pages/Admin/Tenants"));
const Feedback = lazy(() => import("./Pages/FeedbackPage"));

export interface IAppProps { appInsights?: ApplicationInsights }

interface IMessage {
    title: string;
    message: string;
    intent: "error" | "warning" | "success" | "info";
    action?: ButtonProps;
}

function App(props: IAppProps): ReactElement {
    const [isPending] = useTransition();
    const toasterId = useId("toaster");
    const [messages, setMessages] = useState<IMessage[]>([]);
    const [premium, setPremium] = useState<boolean>(false);
    const { isDark, theme } = useTheme();
    const { isSignedIn, isAdminTenant, tenant, loading, token } = useIsSignedIn(true, props.appInsights);
    const [collapseNav, setCollapseNav] = useState<boolean>(window.location.pathname.toLocaleLowerCase() === "/consent");

    useEffect(() => {
        const renewalDate = new Date(tenant?.renewalDate ?? new Date());
        const date30 = new Date(new Date().getTime() + 30 * 24 * 60 * 60 * 1000);
        if (tenant) {
            if (tenant.isPreview) {
                if (renewalDate > new Date()) {
                    setMessages([{ "title": "Preview Mode", "message": `You're experiencing premium features in preview until ${renewalDate.toLocaleDateString()}`, "intent": "info", "action": { "children": "More info", "as": "a", "target": "_blank", "href": "https://www.jisc.ac.uk/cloud/assist-premium" } }]);
                    setPremium(true);
                } else if (renewalDate < new Date()) {
                    setMessages([{ "title": "Preview Expired", "message": `Your preview expired on ${renewalDate.toLocaleDateString()}. You're now using the free version`, "intent": "error", "action": { "children": "Purchase", "as": "a", "target": "_blank", "href": "https://www.jisc.ac.uk/cloud/assist-premium" } }]);
                    setPremium(false);
                }
            } else if (!tenant.isPreview) {
                if (renewalDate < date30 && renewalDate > new Date()) {
                    setMessages([{ "title": "Premium expiring soon", "message": `Your subscription will expire on ${renewalDate.toLocaleDateString()}.`, "intent": "warning", "action": { "children": "Renew", "as": "a", "target": "_blank", "href": "https://www.jisc.ac.uk/cloud/assist-premium" } }]);
                    setPremium(true);
                } else if (renewalDate > new Date()) {
                    setMessages([{ "title": "Premium Active", "message": `Your subscription will expire on ${renewalDate.toLocaleDateString()}.`, "intent": "success" }]);
                    setPremium(true);
                } else if (renewalDate < new Date()) {
                    setMessages([{ "title": "Premium Expired", "message": `Your subscription expired on ${renewalDate.toLocaleDateString()}. You're now using the free version`, "intent": "error", "action": { "children": "Renew", "as": "a", "target": "_blank", "href": "https://www.jisc.ac.uk/cloud/assist-premium" } }]);
                    setPremium(false);
                }
            }
        }
    }, [tenant]);

    return (
        <FluentProvider theme={theme}>
            <JToolContext.Provider value={new JTool(tenant, Providers.client, token, toasterId, isAdminTenant)}>
                <div id="jtool" className={`App ${isDark ? "mgt-dark" : ""}${collapseNav ? " collapseNav" : ""}`}>
                    <div style={{ display: "none", visibility: "hidden" }}><ThemeToggle darkModeActive={isDark} /></div>
                    <header id="header">
                        <div className="App-logo">
                            <img src={logo} alt="Jisc Logo" />
                            <div className="App-title">
                                <Subtitle2Stronger>Cloud Assist</Subtitle2Stronger>
                                <Badge appearance='tint' size='large' shape="rounded" color='warning'>BETA</Badge>
                            </div>
                            <MessageBarGroup animate="both">
                                {messages.map((m, i) => (<MessageBar key={i} intent={m.intent}>
                                    <MessageBarBody>
                                        <MessageBarTitle>{m.title}</MessageBarTitle>
                                        {m.message}
                                    </MessageBarBody>
                                    <MessageBarActions>
                                        {m.action && <Button {...m.action} />}
                                    </MessageBarActions>
                                </MessageBar>))}
                            </MessageBarGroup>
                        </div>
                        {isSignedIn && <Login></Login>}
                    </header>
                    <Nav collapseNav={collapseNav} onCollapseNav={setCollapseNav} />
                    <div id="content">
                        {(loading || isPending) && <ProgressBar />}
                        {<Routes>
                            <Route path="*" element={<HomePage loading={loading} />} />
                            <Route path="/about/*" element={<About />} />
                            <Route path="/permissions" element={<Permissions />} />
                            <Route path="/consent" element={<Consent />} />
                            {isSignedIn && <>
                                {(premium || tenant?.isReadonly || isAdminTenant) && <>
                                    <Route path="/automations" element={<Tenants />} />
                                    <Route path="/automations/logs" element={<Logs Mode={LogType.Tenant} />} />
                                    <Route path="/database/*" element={<Database />} />
                                    <Route path="/reports/*" element={<Reports />} />
                                </>}
                                <Route path="/feedback/*" element={<Feedback />} />
                                <Route path="/health/*" element={<HealthCheck />} />
                                {isAdminTenant && <>
                                    <Route path="/admin" element={<Admin />} />
                                    <Route path="/admin/health" element={<AdminHealthChecks />} />
                                    <Route path="/admin/tenants" element={<AdminTenants />} />
                                </>}
                            </>}
                        </Routes>}
                    </div>
                </div>
                <Toaster toasterId={toasterId} />
            </JToolContext.Provider>
        </FluentProvider>
    );
}

export default App;