import { Title1 } from "@fluentui/react-components";
import { AppInsightsContext, AppInsightsErrorBoundary, ReactPlugin } from "@microsoft/applicationinsights-react-js";
import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import { LoginType, Providers } from "@microsoft/mgt-element";
import { Msal2Provider } from "@microsoft/mgt-msal2-provider";
import { createBrowserHistory } from "history";
import React from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import "./index.css";
import reportWebVitals from "./reportWebVitals";

Providers.globalProvider = new Msal2Provider({
    clientId: "dcae8e21-3fe3-4447-9e3b-bf7462bf5a1b",
    loginType: LoginType.Redirect,
    redirectUri: window.location.protocol + "//" + window.location.host,
    scopes: ["user.read", "openid", "offline_access", "api://820855a8-604e-45e3-842c-73081b0396cc/user"],
    customHosts: ["assist-preprod.cloud.jisc.ac.uk", "service.powerapps.com", "api.bap.microsoft.com", "api.powerbi.com"]
});
const browserHistory = createBrowserHistory();
const reactPlugin = new ReactPlugin();
const appInsights = new ApplicationInsights({
    config: {
        connectionString: "InstrumentationKey=070c409d-519c-4de5-a1c0-56d279f73c70;IngestionEndpoint=https://uksouth-1.in.applicationinsights.azure.com/;LiveEndpoint=https://uksouth.livediagnostics.monitor.azure.com/",
        enableAutoRouteTracking: true,
        autoTrackPageVisitTime: true,
        enableRequestHeaderTracking: true,
        enableResponseHeaderTracking: true,
        disableCookiesUsage: true,
        extensions: [reactPlugin],
        extensionConfig: {
            [reactPlugin.identifier]: { history: browserHistory }
        }
    }
});
appInsights.loadAppInsights();
if (window.location.host === "365companiondemo.cloud.jisc.ac.uk") window.location.replace("https://assist-preprod.cloud.jisc.ac.uk");
const container = document.getElementById("root");
const root = createRoot(container!);
root.render(<React.StrictMode>
    <BrowserRouter>
        <AppInsightsContext.Provider value={reactPlugin}>
            <AppInsightsErrorBoundary onError={() => <Title1>I believe something went wrong</Title1>} appInsights={reactPlugin}>
                <App appInsights={appInsights} />
            </AppInsightsErrorBoundary>
        </AppInsightsContext.Provider>
    </BrowserRouter>
</React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();