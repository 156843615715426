import { AccordionProps, BreadcrumbProps, makeStyles, Text, Title2, tokens, useId } from "@fluentui/react-components";
import * as React from "react";
import useTitle from "../Hooks/useTitle";
interface IPageTitleProps {
    title: string;
    subtitle?: string | JSX.Element | JSX.Element[];
    icon?: React.ReactElement;
    children?: React.ReactElement<BreadcrumbProps> | [React.ReactElement<BreadcrumbProps>,React.ReactElement<AccordionProps>];
}

const useStyles = makeStyles({
    title: {
        color: tokens.colorBrandForeground1,
        gridArea: "title"
    },
    subtitle: {
        gridArea: "description"
    }
})

export default function PageTitle(props: IPageTitleProps): React.ReactElement<IPageTitleProps> {
    const id = useId("companion-Title");
    const breadcrumbs = props.children && React.cloneElement(Array.isArray(props.children) ? props.children[0] : props.children, { style: {gridArea: "breadcrumbs"} });
    const accordion = props.children && Array.isArray(props.children) && props.children.length > 1 && React.cloneElement(props.children[1], { style: {gridArea: "accordion"} });
    const styles = useStyles();
    useTitle(props.title ?? "", false);

    return (<header id={id} className={`pageTitle ${props.icon ? "withIcon" : ""}`}>
        {breadcrumbs}
        {props.icon && <span className='icon'>{props.icon}</span>}
        <Title2 className={styles.title} as="h1">{props.title}</Title2>
        <Text className={styles.subtitle}>{props.subtitle}</Text>
        {accordion}
    </header>);
}