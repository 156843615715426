// @ts-nocheck

export enum ReportMode {
    Device = "Device",
    CESummary = "CESummary",
    OSSummary = "OSSummary",
    Outdated = "Outdated",
    Stale = "Stale"
}

export enum OsType {
    Android = "Android",
    Windows = "Windows",
    MacOS = "MacOS",
    iOS = "iOS",
    Linux = "Linux"
}

export enum Priority {
    Low = "Low",
    Standard = "Standard",
    High = "High",
    Urgent = "Urgent",
    Critical = "Critical",
    Informative = "Informative"
}

export enum Impact {
    Low = "Low",
    Medium = "Medium",
    High = "High",
    Severe = "Severe"
}

export enum Source {
    Jisc = "Jisc",
    NCSC = "NCSC",
    CIS = "CIS",
    Microsoft = "Microsoft",
    NIST = "NIST",
    CE = "CE"
}

export enum NumberCompare {
    LessThan = "LessThan",
    LessThanEquals = "LessThanEquals",
    Equals = "Equals",
    GreaterThanEquals = "GreaterThanEquals",
    GreaterThan = "GreaterThan",
    NotEquals = "NotEquals"
}

export enum LogEntrySource {
    PolicyUpdate = "PolicyUpdate",
    DeviceUpdate = "DeviceUpdate",
    AndroidRename = "AndroidRename",
    DeviceCleanup = "DeviceCleanup",
    FeedUpdate = "FeedUpdate"
}

export enum PolicyType {
    MacOSCompliancePolicy = "MacOSCompliancePolicy",
    IosCompliancePolicy = "IosCompliancePolicy",
    AndroidDeviceOwnerCompliancePolicy = "AndroidDeviceOwnerCompliancePolicy",
    AndroidCompliancePolicy = "AndroidCompliancePolicy",
    Windows10CompliancePolicy = "Windows10CompliancePolicy",
    LinuxCompliancePolicy = "LinuxCompliancePolicy",
    AndroidManagedAppProtections = "AndroidManagedAppProtections",
    IosManagedAppProtections = "IosManagedAppProtections",
    WindowsManagedAppProtections = "WindowsManagedAppProtections",
    WindowsQualityUpdateProfiles = "WindowsQualityUpdateProfiles",
    Nudge = "Nudge",
    DeclarativeMacOSConfigrationPolicy = "DeclarativeMacOSConfigrationPolicy",
    DeclarativeiOSConfigurationPolicy = "DeclarativeiOSConfigurationPolicy",
    AndroidWorkProfileCompliancePolicy = "AndroidWorkProfileCompliancePolicy",
    AndroidForWorkCompliancePolicy = "AndroidForWorkCompliancePolicy"
}

export enum FeedbackState {
    New = "New",
    Open = "Open",
    Closed = "Closed"
}

export enum FeedbackType {
    Bug = "Bug",
    FeatureRequest = "FeatureRequest",
    Feedback = "Feedback",
    Other = "Other"
}

export enum TraceEventType {
    Critical = "Critical",
    Error = "Error",
    Warning = "Warning",
    Information = "Information",
    Verbose = "Verbose",
    Start = "Start",
    Stop = "Stop",
    Suspend = "Suspend",
    Resume = "Resume",
    Transfer = "Transfer"
}

export interface Model {
    /**
     * **Key Property**: This is a key property used to identify the entity.<br/>**Managed**: This property is managed on the server side and cannot be edited.
     *
     * OData Attributes:
     * |Attribute Name | Attribute Value |
     * | --- | ---|
     * | Name | `id` |
     * | Type | `Edm.Guid` |
     * | Nullable | `false` |
     */
    id: string;
    /**
     *
     * OData Attributes:
     * |Attribute Name | Attribute Value |
     * | --- | ---|
     * | Name | `name` |
     * | Type | `Edm.String` |
     * | Nullable | `false` |
     */
    name: string;
    /**
     *
     * OData Attributes:
     * |Attribute Name | Attribute Value |
     * | --- | ---|
     * | Name | `description` |
     * | Type | `Edm.String` |
     * | Nullable | `false` |
     */
    description: string;
    /**
     *
     * OData Attributes:
     * |Attribute Name | Attribute Value |
     * | --- | ---|
     * | Name | `manufacturer` |
     * | Type | `Edm.Guid` |
     * | Nullable | `false` |
     */
    manufacturer: string;
    /**
     *
     * OData Attributes:
     * |Attribute Name | Attribute Value |
     * | --- | ---|
     * | Name | `released` |
     * | Type | `Edm.DateTimeOffset` |
     */
    released: string | null;
    /**
     *
     * OData Attributes:
     * |Attribute Name | Attribute Value |
     * | --- | ---|
     * | Name | `supportEnd` |
     * | Type | `Edm.DateTimeOffset` |
     */
    supportEnd: string | null;
    /**
     *
     * OData Attributes:
     * |Attribute Name | Attribute Value |
     * | --- | ---|
     * | Name | `type` |
     * | Type | `Jisc.Cloud.Assist.Models.OsType` |
     * | Nullable | `false` |
     */
    type: OsType;
    /**
     *
     * OData Attributes:
     * |Attribute Name | Attribute Value |
     * | --- | ---|
     * | Name | `tenant` |
     * | Type | `Edm.Guid` |
     */
    tenant: string | null;
    /**
     *
     * OData Attributes:
     * |Attribute Name | Attribute Value |
     * | --- | ---|
     * | Name | `aliases` |
     * | Type | `Collection(Edm.String)` |
     */
    aliases: string[];
    /**
     * **Managed**: This property is managed on the server side and cannot be edited.
     *
     * OData Attributes:
     * |Attribute Name | Attribute Value |
     * | --- | ---|
     * | Name | `created` |
     * | Type | `Edm.DateTimeOffset` |
     */
    created: string | null;
    /**
     * **Managed**: This property is managed on the server side and cannot be edited.
     *
     * OData Attributes:
     * |Attribute Name | Attribute Value |
     * | --- | ---|
     * | Name | `modified` |
     * | Type | `Edm.DateTimeOffset` |
     */
    modified: string | null;
    /**
     * **Managed**: This property is managed on the server side and cannot be edited.
     *
     * OData Attributes:
     * |Attribute Name | Attribute Value |
     * | --- | ---|
     * | Name | `createdBy` |
     * | Type | `Edm.String` |
     */
    createdBy: string | null;
    /**
     * **Managed**: This property is managed on the server side and cannot be edited.
     *
     * OData Attributes:
     * |Attribute Name | Attribute Value |
     * | --- | ---|
     * | Name | `modifiedBy` |
     * | Type | `Edm.String` |
     */
    modifiedBy: string | null;
}

export type ModelId = string | {id: string};

export interface EditableModel extends Pick<Model, "name" | "description" | "manufacturer" | "type">, Partial<Pick<Model, "released" | "supportEnd" | "tenant" | "aliases">> {
}

export interface HealthCheckSetting {
    /**
     * **Key Property**: This is a key property used to identify the entity.<br/>**Managed**: This property is managed on the server side and cannot be edited.
     *
     * OData Attributes:
     * |Attribute Name | Attribute Value |
     * | --- | ---|
     * | Name | `id` |
     * | Type | `Edm.Guid` |
     * | Nullable | `false` |
     */
    id: string;
    /**
     *
     * OData Attributes:
     * |Attribute Name | Attribute Value |
     * | --- | ---|
     * | Name | `name` |
     * | Type | `Edm.String` |
     * | Nullable | `false` |
     */
    name: string;
    /**
     *
     * OData Attributes:
     * |Attribute Name | Attribute Value |
     * | --- | ---|
     * | Name | `displayName` |
     * | Type | `Edm.String` |
     */
    displayName: string | null;
    /**
     *
     * OData Attributes:
     * |Attribute Name | Attribute Value |
     * | --- | ---|
     * | Name | `reason` |
     * | Type | `Edm.String` |
     * | Nullable | `false` |
     */
    reason: string;
    /**
     *
     * OData Attributes:
     * |Attribute Name | Attribute Value |
     * | --- | ---|
     * | Name | `priority` |
     * | Type | `Jisc.Cloud.Assist.Models.Priority` |
     */
    priority: Priority | null;
    /**
     *
     * OData Attributes:
     * |Attribute Name | Attribute Value |
     * | --- | ---|
     * | Name | `impact` |
     * | Type | `Jisc.Cloud.Assist.Models.Impact` |
     */
    impact: Impact | null;
    /**
     *
     * OData Attributes:
     * |Attribute Name | Attribute Value |
     * | --- | ---|
     * | Name | `moreInfoLink` |
     * | Type | `Edm.String` |
     */
    moreInfoLink: string | null;
    /**
     * **Managed**: This property is managed on the server side and cannot be edited.
     *
     * OData Attributes:
     * |Attribute Name | Attribute Value |
     * | --- | ---|
     * | Name | `createdBy` |
     * | Type | `Edm.String` |
     */
    createdBy: string | null;
    /**
     * **Managed**: This property is managed on the server side and cannot be edited.
     *
     * OData Attributes:
     * |Attribute Name | Attribute Value |
     * | --- | ---|
     * | Name | `created` |
     * | Type | `Edm.DateTimeOffset` |
     */
    created: string | null;
    /**
     * **Managed**: This property is managed on the server side and cannot be edited.
     *
     * OData Attributes:
     * |Attribute Name | Attribute Value |
     * | --- | ---|
     * | Name | `modifiedBy` |
     * | Type | `Edm.String` |
     */
    modifiedBy: string | null;
    /**
     * **Managed**: This property is managed on the server side and cannot be edited.
     *
     * OData Attributes:
     * |Attribute Name | Attribute Value |
     * | --- | ---|
     * | Name | `modified` |
     * | Type | `Edm.DateTimeOffset` |
     */
    modified: string | null;
    /**
     *
     * OData Attributes:
     * |Attribute Name | Attribute Value |
     * | --- | ---|
     * | Name | `category` |
     * | Type | `Edm.String` |
     */
    category: string | null;
    /**
     *
     * OData Attributes:
     * |Attribute Name | Attribute Value |
     * | --- | ---|
     * | Name | `source` |
     * | Type | `Jisc.Cloud.Assist.Models.Source` |
     */
    source: Source | null;
}

export type HealthCheckSettingId = string | {id: string};

export interface EditableHealthCheckSetting extends Pick<HealthCheckSetting, "name" | "reason">, Partial<Pick<HealthCheckSetting, "displayName" | "priority" | "impact" | "moreInfoLink" | "category" | "source">> {
}

export interface HealthCheckValueSetting extends HealthCheckSetting {
    /**
     *
     * OData Attributes:
     * |Attribute Name | Attribute Value |
     * | --- | ---|
     * | Name | `value` |
     * | Type | `Edm.Untyped` |
     */
    value: string | null;
    /**
     *
     * OData Attributes:
     * |Attribute Name | Attribute Value |
     * | --- | ---|
     * | Name | `not` |
     * | Type | `Edm.Boolean` |
     */
    not: boolean | null;
}

export interface EditableHealthCheckValueSetting extends Pick<HealthCheckValueSetting, "name" | "reason">, Partial<Pick<HealthCheckValueSetting, "displayName" | "priority" | "impact" | "moreInfoLink" | "category" | "source" | "value" | "not">> {
}

export interface HealthCheckCountSetting extends HealthCheckSetting {
    /**
     *
     * OData Attributes:
     * |Attribute Name | Attribute Value |
     * | --- | ---|
     * | Name | `count` |
     * | Type | `Edm.Int32` |
     */
    count: number | null;
    /**
     *
     * OData Attributes:
     * |Attribute Name | Attribute Value |
     * | --- | ---|
     * | Name | `compare` |
     * | Type | `Jisc.Cloud.Assist.Models.NumberCompare` |
     */
    compare: NumberCompare | null;
}

export interface EditableHealthCheckCountSetting extends Pick<HealthCheckCountSetting, "name" | "reason">, Partial<Pick<HealthCheckCountSetting, "displayName" | "priority" | "impact" | "moreInfoLink" | "category" | "source" | "count" | "compare">> {
}

export interface Manufacturer {
    /**
     * **Key Property**: This is a key property used to identify the entity.<br/>**Managed**: This property is managed on the server side and cannot be edited.
     *
     * OData Attributes:
     * |Attribute Name | Attribute Value |
     * | --- | ---|
     * | Name | `id` |
     * | Type | `Edm.Guid` |
     * | Nullable | `false` |
     */
    id: string;
    /**
     *
     * OData Attributes:
     * |Attribute Name | Attribute Value |
     * | --- | ---|
     * | Name | `name` |
     * | Type | `Edm.String` |
     * | Nullable | `false` |
     */
    name: string;
    /**
     *
     * OData Attributes:
     * |Attribute Name | Attribute Value |
     * | --- | ---|
     * | Name | `siteUri` |
     * | Type | `Edm.String` |
     */
    siteUri: string | null;
    /**
     *
     * OData Attributes:
     * |Attribute Name | Attribute Value |
     * | --- | ---|
     * | Name | `aliases` |
     * | Type | `Collection(Edm.String)` |
     */
    aliases: string[];
    /**
     * **Managed**: This property is managed on the server side and cannot be edited.
     *
     * OData Attributes:
     * |Attribute Name | Attribute Value |
     * | --- | ---|
     * | Name | `created` |
     * | Type | `Edm.DateTimeOffset` |
     */
    created: string | null;
    /**
     * **Managed**: This property is managed on the server side and cannot be edited.
     *
     * OData Attributes:
     * |Attribute Name | Attribute Value |
     * | --- | ---|
     * | Name | `modified` |
     * | Type | `Edm.DateTimeOffset` |
     */
    modified: string | null;
    /**
     * **Managed**: This property is managed on the server side and cannot be edited.
     *
     * OData Attributes:
     * |Attribute Name | Attribute Value |
     * | --- | ---|
     * | Name | `createdBy` |
     * | Type | `Edm.String` |
     */
    createdBy: string | null;
    /**
     * **Managed**: This property is managed on the server side and cannot be edited.
     *
     * OData Attributes:
     * |Attribute Name | Attribute Value |
     * | --- | ---|
     * | Name | `modifiedBy` |
     * | Type | `Edm.String` |
     */
    modifiedBy: string | null;
}

export type ManufacturerId = string | {id: string};

export interface EditableManufacturer extends Pick<Manufacturer, "name">, Partial<Pick<Manufacturer, "siteUri" | "aliases">> {
}

export interface LogEntry {
    /**
     * **Key Property**: This is a key property used to identify the entity.<br/>**Managed**: This property is managed on the server side and cannot be edited.
     *
     * OData Attributes:
     * |Attribute Name | Attribute Value |
     * | --- | ---|
     * | Name | `id` |
     * | Type | `Edm.Guid` |
     * | Nullable | `false` |
     */
    id: string;
    /**
     *
     * OData Attributes:
     * |Attribute Name | Attribute Value |
     * | --- | ---|
     * | Name | `parentId` |
     * | Type | `Edm.Guid` |
     * | Nullable | `false` |
     */
    parentId: string;
    /**
     *
     * OData Attributes:
     * |Attribute Name | Attribute Value |
     * | --- | ---|
     * | Name | `date` |
     * | Type | `Edm.DateTimeOffset` |
     * | Nullable | `false` |
     */
    date: string;
    /**
     *
     * OData Attributes:
     * |Attribute Name | Attribute Value |
     * | --- | ---|
     * | Name | `type` |
     * | Type | `System.Diagnostics.TraceEventType` |
     * | Nullable | `false` |
     */
    type: TraceEventType;
    /**
     *
     * OData Attributes:
     * |Attribute Name | Attribute Value |
     * | --- | ---|
     * | Name | `message` |
     * | Type | `Edm.String` |
     * | Nullable | `false` |
     */
    message: string;
    /**
     *
     * OData Attributes:
     * |Attribute Name | Attribute Value |
     * | --- | ---|
     * | Name | `details` |
     * | Type | `Edm.String` |
     * | Nullable | `false` |
     */
    details: string;
    /**
     *
     * OData Attributes:
     * |Attribute Name | Attribute Value |
     * | --- | ---|
     * | Name | `source` |
     * | Type | `Jisc.Cloud.Assist.Models.LogEntrySource` |
     */
    source: LogEntrySource | null;
}

export type LogEntryId = string | {id: string};

export interface EditableLogEntry extends Pick<LogEntry, "parentId" | "date" | "type" | "message" | "details">, Partial<Pick<LogEntry, "source">> {
}

export interface TenantLogEntry extends LogEntry {
}

export interface EditableTenantLogEntry extends Pick<TenantLogEntry, "parentId" | "date" | "type" | "message" | "details">, Partial<Pick<TenantLogEntry, "source">> {
}

export interface FeedLogEntry extends LogEntry {
}

export interface EditableFeedLogEntry extends Pick<FeedLogEntry, "parentId" | "date" | "type" | "message" | "details">, Partial<Pick<FeedLogEntry, "source">> {
}

export interface Feed {
    /**
     *
     * OData Attributes:
     * |Attribute Name | Attribute Value |
     * | --- | ---|
     * | Name | `enabled` |
     * | Type | `Edm.Boolean` |
     * | Nullable | `false` |
     */
    enabled: boolean;
    /**
     * **Key Property**: This is a key property used to identify the entity.<br/>**Managed**: This property is managed on the server side and cannot be edited.
     *
     * OData Attributes:
     * |Attribute Name | Attribute Value |
     * | --- | ---|
     * | Name | `id` |
     * | Type | `Edm.Guid` |
     * | Nullable | `false` |
     */
    id: string;
    /**
     *
     * OData Attributes:
     * |Attribute Name | Attribute Value |
     * | --- | ---|
     * | Name | `tenant` |
     * | Type | `Edm.Guid` |
     */
    tenant: string | null;
    /**
     *
     * OData Attributes:
     * |Attribute Name | Attribute Value |
     * | --- | ---|
     * | Name | `name` |
     * | Type | `Edm.String` |
     * | Nullable | `false` |
     */
    name: string;
    /**
     *
     * OData Attributes:
     * |Attribute Name | Attribute Value |
     * | --- | ---|
     * | Name | `uri` |
     * | Type | `Edm.String` |
     * | Nullable | `false` |
     */
    uri: string;
    /**
     *
     * OData Attributes:
     * |Attribute Name | Attribute Value |
     * | --- | ---|
     * | Name | `manufacturers` |
     * | Type | `Collection(Edm.Guid)` |
     * | Nullable | `false` |
     */
    manufacturers: string[];
    /**
     *
     * OData Attributes:
     * |Attribute Name | Attribute Value |
     * | --- | ---|
     * | Name | `osType` |
     * | Type | `Jisc.Cloud.Assist.Models.OsType` |
     * | Nullable | `false` |
     */
    osType: OsType;
    /**
     *
     * OData Attributes:
     * |Attribute Name | Attribute Value |
     * | --- | ---|
     * | Name | `refreshed` |
     * | Type | `Edm.DateTimeOffset` |
     */
    refreshed: string | null;
    /**
     *
     * OData Attributes:
     * |Attribute Name | Attribute Value |
     * | --- | ---|
     * | Name | `autoEnabled` |
     * | Type | `Edm.Boolean` |
     * | Nullable | `false` |
     */
    autoEnabled: boolean;
    /**
     *
     * OData Attributes:
     * |Attribute Name | Attribute Value |
     * | --- | ---|
     * | Name | `schedule` |
     * | Type | `Jisc.Cloud.Assist.Models.RefreshCycle` |
     */
    schedule: RefreshCycle | null;
    /**
     * **Managed**: This property is managed on the server side and cannot be edited.
     *
     * OData Attributes:
     * |Attribute Name | Attribute Value |
     * | --- | ---|
     * | Name | `created` |
     * | Type | `Edm.DateTimeOffset` |
     */
    created: string | null;
    /**
     * **Managed**: This property is managed on the server side and cannot be edited.
     *
     * OData Attributes:
     * |Attribute Name | Attribute Value |
     * | --- | ---|
     * | Name | `createdBy` |
     * | Type | `Edm.String` |
     */
    createdBy: string | null;
    /**
     * **Managed**: This property is managed on the server side and cannot be edited.
     *
     * OData Attributes:
     * |Attribute Name | Attribute Value |
     * | --- | ---|
     * | Name | `modified` |
     * | Type | `Edm.DateTimeOffset` |
     */
    modified: string | null;
    /**
     * **Managed**: This property is managed on the server side and cannot be edited.
     *
     * OData Attributes:
     * |Attribute Name | Attribute Value |
     * | --- | ---|
     * | Name | `modifiedBy` |
     * | Type | `Edm.String` |
     */
    modifiedBy: string | null;
    /**
     *
     * OData Attributes:
     * |Attribute Name | Attribute Value |
     * | --- | ---|
     * | Name | `oAuthKey` |
     * | Type | `Edm.String` |
     */
    oAuthKey: string | null;
    /**
     *
     * OData Attributes:
     * |Attribute Name | Attribute Value |
     * | --- | ---|
     * | Name | `oAuthSecret` |
     * | Type | `Edm.String` |
     */
    oAuthSecret: string | null;
    /**
     *
     * OData Attributes:
     * |Attribute Name | Attribute Value |
     * | --- | ---|
     * | Name | `addAll` |
     * | Type | `Edm.Boolean` |
     */
    addAll: boolean | null;
    /**
     *
     * OData Attributes:
     * |Attribute Name | Attribute Value |
     * | --- | ---|
     * | Name | `ignores` |
     * | Type | `Collection(Edm.String)` |
     */
    ignores: string[];
}

export type FeedId = string | {id: string};

export interface EditableFeed extends Pick<Feed, "enabled" | "name" | "uri" | "manufacturers" | "osType" | "autoEnabled">, Partial<Pick<Feed, "tenant" | "refreshed" | "oAuthKey" | "oAuthSecret" | "addAll" | "ignores">> {
    schedule?: EditableRefreshCycle | null;
}

export interface Tenant {
    /**
     * **Key Property**: This is a key property used to identify the entity.<br/>**Managed**: This property is managed on the server side and cannot be edited.
     *
     * OData Attributes:
     * |Attribute Name | Attribute Value |
     * | --- | ---|
     * | Name | `id` |
     * | Type | `Edm.Guid` |
     * | Nullable | `false` |
     */
    id: string;
    /**
     *
     * OData Attributes:
     * |Attribute Name | Attribute Value |
     * | --- | ---|
     * | Name | `name` |
     * | Type | `Edm.String` |
     * | Nullable | `false` |
     */
    name: string;
    /**
     *
     * OData Attributes:
     * |Attribute Name | Attribute Value |
     * | --- | ---|
     * | Name | `renewalDate` |
     * | Type | `Edm.DateTimeOffset` |
     * | Nullable | `false` |
     */
    renewalDate: string;
    /**
     *
     * OData Attributes:
     * |Attribute Name | Attribute Value |
     * | --- | ---|
     * | Name | `isPreview` |
     * | Type | `Edm.Boolean` |
     * | Nullable | `false` |
     */
    isPreview: boolean;
    /**
     *
     * OData Attributes:
     * |Attribute Name | Attribute Value |
     * | --- | ---|
     * | Name | `hasCE` |
     * | Type | `Edm.Boolean` |
     */
    hasCe: boolean | null;
    /**
     *
     * OData Attributes:
     * |Attribute Name | Attribute Value |
     * | --- | ---|
     * | Name | `isReadonly` |
     * | Type | `Edm.Boolean` |
     * | Nullable | `false` |
     */
    isReadonly: boolean;
    /**
     *
     * OData Attributes:
     * |Attribute Name | Attribute Value |
     * | --- | ---|
     * | Name | `updateAD` |
     * | Type | `Edm.Boolean` |
     * | Nullable | `false` |
     */
    updateAd: boolean;
    /**
     *
     * OData Attributes:
     * |Attribute Name | Attribute Value |
     * | --- | ---|
     * | Name | `updateCAP` |
     * | Type | `Edm.Boolean` |
     * | Nullable | `false` |
     */
    updateCap: boolean;
    /**
     *
     * OData Attributes:
     * |Attribute Name | Attribute Value |
     * | --- | ---|
     * | Name | `refreshed` |
     * | Type | `Edm.DateTimeOffset` |
     */
    refreshed: string | null;
    /**
     *
     * OData Attributes:
     * |Attribute Name | Attribute Value |
     * | --- | ---|
     * | Name | `updateSchedule` |
     * | Type | `Jisc.Cloud.Assist.Models.RefreshCycle` |
     */
    updateSchedule: RefreshCycle | null;
    /**
     * **Managed**: This property is managed on the server side and cannot be edited.
     *
     * OData Attributes:
     * |Attribute Name | Attribute Value |
     * | --- | ---|
     * | Name | `created` |
     * | Type | `Edm.DateTimeOffset` |
     */
    created: string | null;
    /**
     * **Managed**: This property is managed on the server side and cannot be edited.
     *
     * OData Attributes:
     * |Attribute Name | Attribute Value |
     * | --- | ---|
     * | Name | `modified` |
     * | Type | `Edm.DateTimeOffset` |
     */
    modified: string | null;
    /**
     *
     * OData Attributes:
     * |Attribute Name | Attribute Value |
     * | --- | ---|
     * | Name | `devicesDelta` |
     * | Type | `Edm.String` |
     */
    devicesDelta: string | null;
    /**
     *
     * OData Attributes:
     * |Attribute Name | Attribute Value |
     * | --- | ---|
     * | Name | `deltaSchedule` |
     * | Type | `Jisc.Cloud.Assist.Models.RefreshCycle` |
     */
    deltaSchedule: RefreshCycle | null;
    /**
     *
     * OData Attributes:
     * |Attribute Name | Attribute Value |
     * | --- | ---|
     * | Name | `updateAndroidDeviceNames` |
     * | Type | `Edm.Boolean` |
     */
    updateAndroidDeviceNames: boolean | null;
    /**
     *
     * OData Attributes:
     * |Attribute Name | Attribute Value |
     * | --- | ---|
     * | Name | `androidDeviceNameFormat` |
     * | Type | `Edm.String` |
     */
    androidDeviceNameFormat: string | null;
    /**
     *
     * OData Attributes:
     * |Attribute Name | Attribute Value |
     * | --- | ---|
     * | Name | `cleanUpDevices` |
     * | Type | `Edm.Boolean` |
     */
    cleanUpDevices: boolean | null;
    /**
     *
     * OData Attributes:
     * |Attribute Name | Attribute Value |
     * | --- | ---|
     * | Name | `deviceCleanupAge` |
     * | Type | `Edm.Int32` |
     */
    deviceCleanupAge: number | null;
    /**
     *
     * OData Attributes:
     * |Attribute Name | Attribute Value |
     * | --- | ---|
     * | Name | `deviceWarningWeeks` |
     * | Type | `Edm.Int32` |
     */
    deviceWarningWeeks: number | null;
    /**
     *
     * OData Attributes:
     * |Attribute Name | Attribute Value |
     * | --- | ---|
     * | Name | `osWarningWeeks` |
     * | Type | `Edm.Int32` |
     */
    osWarningWeeks: number | null;
    /**
     * **Managed**: This property is managed on the server side and cannot be edited.
     *
     * OData Attributes:
     * |Attribute Name | Attribute Value |
     * | --- | ---|
     * | Name | `createdBy` |
     * | Type | `Edm.String` |
     */
    createdBy: string | null;
    /**
     * **Managed**: This property is managed on the server side and cannot be edited.
     *
     * OData Attributes:
     * |Attribute Name | Attribute Value |
     * | --- | ---|
     * | Name | `modifiedBy` |
     * | Type | `Edm.String` |
     */
    modifiedBy: string | null;
    /**
     *
     * OData Attributes:
     * |Attribute Name | Attribute Value |
     * | --- | ---|
     * | Name | `logRetention` |
     * | Type | `Edm.Int32` |
     */
    logRetention: number | null;
    /**
     *
     * OData Attributes:
     * |Attribute Name | Attribute Value |
     * | --- | ---|
     * | Name | `healthCheckIgnores` |
     * | Type | `Collection(Jisc.Cloud.Assist.Models.TenantModels.HCIgnore)` |
     * | Nullable | `false` |
     */
    healthCheckIgnores: HcIgnore[];
    /**
     *
     * OData Attributes:
     * |Attribute Name | Attribute Value |
     * | --- | ---|
     * | Name | `groupTarget` |
     * | Type | `Edm.String` |
     */
    groupTarget: string | null;
    /**
     *
     * OData Attributes:
     * |Attribute Name | Attribute Value |
     * | --- | ---|
     * | Name | `attributeMapping` |
     * | Type | `Jisc.Cloud.Assist.Models.TenantModels.AttributeMapping` |
     */
    attributeMapping: AttributeMapping | null;
    /**
     *
     * OData Attributes:
     * |Attribute Name | Attribute Value |
     * | --- | ---|
     * | Name | `policies` |
     * | Type | `Collection(Jisc.Cloud.Assist.Models.TenantModels.PolicyBase)` |
     */
    policies?: PolicyBase[];
}

export type TenantId = string | {id: string};

export interface EditableTenant extends Pick<Tenant, "name" | "renewalDate" | "isPreview" | "isReadonly" | "updateAd" | "updateCap">, Partial<Pick<Tenant, "hasCe" | "refreshed" | "devicesDelta" | "updateAndroidDeviceNames" | "androidDeviceNameFormat" | "cleanUpDevices" | "deviceCleanupAge" | "deviceWarningWeeks" | "osWarningWeeks" | "logRetention" | "groupTarget">> {
    updateSchedule?: EditableRefreshCycle | null;
    deltaSchedule?: EditableRefreshCycle | null;
    healthCheckIgnores: EditableHcIgnore[];
    attributeMapping?: EditableAttributeMapping | null;
}

export interface Tenant_AdminConsentParams {
    scopes?: string | null;
}

export interface Tenant_ConsentParams {
    scopes?: string | null;
}

export interface Tenant_ReportParams {
    mode: ReportMode;
    showDisabled: boolean;
    rollup: boolean;
    hideEol: boolean;
    group?: string | null;
}

export interface Tenant_ConsentsParams {
    refresh?: boolean | null;
}

export interface Os {
    /**
     * **Key Property**: This is a key property used to identify the entity.<br/>**Managed**: This property is managed on the server side and cannot be edited.
     *
     * OData Attributes:
     * |Attribute Name | Attribute Value |
     * | --- | ---|
     * | Name | `id` |
     * | Type | `Edm.String` |
     * | Nullable | `false` |
     */
    id: string;
    /**
     *
     * OData Attributes:
     * |Attribute Name | Attribute Value |
     * | --- | ---|
     * | Name | `name` |
     * | Type | `Edm.String` |
     * | Nullable | `false` |
     */
    name: string;
    /**
     *
     * OData Attributes:
     * |Attribute Name | Attribute Value |
     * | --- | ---|
     * | Name | `supportedVersions` |
     * | Type | `Collection(Edm.String)` |
     */
    supportedVersions: string[];
    /**
     *
     * OData Attributes:
     * |Attribute Name | Attribute Value |
     * | --- | ---|
     * | Name | `builds` |
     * | Type | `Collection(Jisc.Cloud.Assist.Models.OSBuild)` |
     */
    builds: OsBuild[];
    /**
     *
     * OData Attributes:
     * |Attribute Name | Attribute Value |
     * | --- | ---|
     * | Name | `refreshed` |
     * | Type | `Edm.DateTimeOffset` |
     */
    refreshed: string | null;
    /**
     * **Managed**: This property is managed on the server side and cannot be edited.
     *
     * OData Attributes:
     * |Attribute Name | Attribute Value |
     * | --- | ---|
     * | Name | `createdBy` |
     * | Type | `Edm.String` |
     */
    createdBy: string | null;
    /**
     * **Managed**: This property is managed on the server side and cannot be edited.
     *
     * OData Attributes:
     * |Attribute Name | Attribute Value |
     * | --- | ---|
     * | Name | `created` |
     * | Type | `Edm.DateTimeOffset` |
     */
    created: string | null;
    /**
     * **Managed**: This property is managed on the server side and cannot be edited.
     *
     * OData Attributes:
     * |Attribute Name | Attribute Value |
     * | --- | ---|
     * | Name | `modifiedBy` |
     * | Type | `Edm.String` |
     */
    modifiedBy: string | null;
    /**
     * **Managed**: This property is managed on the server side and cannot be edited.
     *
     * OData Attributes:
     * |Attribute Name | Attribute Value |
     * | --- | ---|
     * | Name | `modified` |
     * | Type | `Edm.DateTimeOffset` |
     */
    modified: string | null;
}

export type OsId = string | {id: string};

export interface EditableOs extends Pick<Os, "name">, Partial<Pick<Os, "supportedVersions" | "refreshed">> {
    builds?: EditableOsBuild[];
}

export interface PolicyBase {
    /**
     * **Key Property**: This is a key property used to identify the entity.<br/>**Managed**: This property is managed on the server side and cannot be edited.
     *
     * OData Attributes:
     * |Attribute Name | Attribute Value |
     * | --- | ---|
     * | Name | `id` |
     * | Type | `Edm.String` |
     * | Nullable | `false` |
     */
    id: string;
    /**
     *
     * OData Attributes:
     * |Attribute Name | Attribute Value |
     * | --- | ---|
     * | Name | `displayName` |
     * | Type | `Edm.String` |
     * | Nullable | `false` |
     */
    displayName: string;
    /**
     *
     * OData Attributes:
     * |Attribute Name | Attribute Value |
     * | --- | ---|
     * | Name | `policyType` |
     * | Type | `Jisc.Cloud.Assist.Models.TenantModels.PolicyType` |
     * | Nullable | `false` |
     */
    policyType: PolicyType;
    /**
     *
     * OData Attributes:
     * |Attribute Name | Attribute Value |
     * | --- | ---|
     * | Name | `ignores` |
     * | Type | `Collection(Edm.String)` |
     */
    ignores: string[];
    /**
     *
     * OData Attributes:
     * |Attribute Name | Attribute Value |
     * | --- | ---|
     * | Name | `delay` |
     * | Type | `Edm.Int32` |
     * | Nullable | `false` |
     */
    delay: number;
    /**
     *
     * OData Attributes:
     * |Attribute Name | Attribute Value |
     * | --- | ---|
     * | Name | `warnDuringDelay` |
     * | Type | `Edm.Boolean` |
     */
    warnDuringDelay: boolean | null;
}

export type PolicyBaseId = string | {id: string};

export interface EditablePolicyBase extends Pick<PolicyBase, "displayName" | "policyType" | "delay">, Partial<Pick<PolicyBase, "ignores" | "warnDuringDelay">> {
}

export interface Policy extends PolicyBase {
}

export interface EditablePolicy extends Pick<Policy, "displayName" | "policyType" | "delay">, Partial<Pick<Policy, "ignores" | "warnDuringDelay">> {
}

export interface WindowsPolicy extends PolicyBase {
    /**
     *
     * OData Attributes:
     * |Attribute Name | Attribute Value |
     * | --- | ---|
     * | Name | `allowWindows10` |
     * | Type | `Edm.Boolean` |
     * | Nullable | `false` |
     */
    allowWindows10: boolean;
}

export interface EditableWindowsPolicy extends Pick<WindowsPolicy, "displayName" | "policyType" | "delay" | "allowWindows10">, Partial<Pick<WindowsPolicy, "ignores" | "warnDuringDelay">> {
}

export interface AndroidPolicy extends PolicyBase {
    /**
     *
     * OData Attributes:
     * |Attribute Name | Attribute Value |
     * | --- | ---|
     * | Name | `patchMonths` |
     * | Type | `Edm.Int32` |
     * | Nullable | `false` |
     */
    patchMonths: number;
}

export interface EditableAndroidPolicy extends Pick<AndroidPolicy, "displayName" | "policyType" | "delay" | "patchMonths">, Partial<Pick<AndroidPolicy, "ignores" | "warnDuringDelay">> {
}

export interface DeadlinePolicy extends PolicyBase {
    /**
     *
     * OData Attributes:
     * |Attribute Name | Attribute Value |
     * | --- | ---|
     * | Name | `deadline` |
     * | Type | `Edm.Int32` |
     * | Nullable | `false` |
     */
    deadline: number;
}

export interface EditableDeadlinePolicy extends Pick<DeadlinePolicy, "displayName" | "policyType" | "delay" | "deadline">, Partial<Pick<DeadlinePolicy, "ignores" | "warnDuringDelay">> {
}

export interface DeviceReportEntry {
    /**
     * **Key Property**: This is a key property used to identify the entity.<br/>**Managed**: This property is managed on the server side and cannot be edited.
     *
     * OData Attributes:
     * |Attribute Name | Attribute Value |
     * | --- | ---|
     * | Name | `id` |
     * | Type | `Edm.String` |
     * | Nullable | `false` |
     */
    id: string;
}

export type DeviceReportEntryId = string | {id: string};

export interface EditableDeviceReportEntry {
}

export interface CeDeviceGroupKey extends DeviceReportEntry {
    /**
     *
     * OData Attributes:
     * |Attribute Name | Attribute Value |
     * | --- | ---|
     * | Name | `manufacturer` |
     * | Type | `Edm.String` |
     * | Nullable | `false` |
     */
    manufacturer: string;
    /**
     *
     * OData Attributes:
     * |Attribute Name | Attribute Value |
     * | --- | ---|
     * | Name | `os` |
     * | Type | `Edm.String` |
     * | Nullable | `false` |
     */
    os: string;
    /**
     *
     * OData Attributes:
     * |Attribute Name | Attribute Value |
     * | --- | ---|
     * | Name | `osVersion` |
     * | Type | `Edm.String` |
     * | Nullable | `false` |
     */
    osVersion: string;
    /**
     *
     * OData Attributes:
     * |Attribute Name | Attribute Value |
     * | --- | ---|
     * | Name | `count` |
     * | Type | `Edm.Int32` |
     * | Nullable | `false` |
     */
    count: number;
}

export interface EditableCeDeviceGroupKey extends Pick<CeDeviceGroupKey, "manufacturer" | "os" | "osVersion" | "count"> {
}

export interface CeosGroupKey extends DeviceReportEntry {
    /**
     *
     * OData Attributes:
     * |Attribute Name | Attribute Value |
     * | --- | ---|
     * | Name | `os` |
     * | Type | `Edm.String` |
     * | Nullable | `false` |
     */
    os: string;
    /**
     *
     * OData Attributes:
     * |Attribute Name | Attribute Value |
     * | --- | ---|
     * | Name | `osVersion` |
     * | Type | `Edm.String` |
     * | Nullable | `false` |
     */
    osVersion: string;
    /**
     *
     * OData Attributes:
     * |Attribute Name | Attribute Value |
     * | --- | ---|
     * | Name | `ownership` |
     * | Type | `Edm.String` |
     */
    ownership: string | null;
    /**
     *
     * OData Attributes:
     * |Attribute Name | Attribute Value |
     * | --- | ---|
     * | Name | `released` |
     * | Type | `Edm.DateTimeOffset` |
     */
    released: string | null;
    /**
     *
     * OData Attributes:
     * |Attribute Name | Attribute Value |
     * | --- | ---|
     * | Name | `expires` |
     * | Type | `Edm.DateTimeOffset` |
     */
    expires: string | null;
    /**
     *
     * OData Attributes:
     * |Attribute Name | Attribute Value |
     * | --- | ---|
     * | Name | `devices` |
     * | Type | `Collection(Jisc.Cloud.Assist.Models.Reports.CEDevice)` |
     */
    devices?: CeDevice[];
}

export interface EditableCeosGroupKey extends Pick<CeosGroupKey, "os" | "osVersion">, Partial<Pick<CeosGroupKey, "ownership" | "released" | "expires">> {
}

export interface CeooddKey extends DeviceReportEntry {
    /**
     *
     * OData Attributes:
     * |Attribute Name | Attribute Value |
     * | --- | ---|
     * | Name | `manufacturer` |
     * | Type | `Edm.String` |
     * | Nullable | `false` |
     */
    manufacturer: string;
    /**
     *
     * OData Attributes:
     * |Attribute Name | Attribute Value |
     * | --- | ---|
     * | Name | `model` |
     * | Type | `Edm.String` |
     * | Nullable | `false` |
     */
    model: string;
    /**
     *
     * OData Attributes:
     * |Attribute Name | Attribute Value |
     * | --- | ---|
     * | Name | `ownership` |
     * | Type | `Edm.String` |
     */
    ownership: string | null;
    /**
     *
     * OData Attributes:
     * |Attribute Name | Attribute Value |
     * | --- | ---|
     * | Name | `released` |
     * | Type | `Edm.DateTimeOffset` |
     */
    released: string | null;
    /**
     *
     * OData Attributes:
     * |Attribute Name | Attribute Value |
     * | --- | ---|
     * | Name | `expires` |
     * | Type | `Edm.DateTimeOffset` |
     */
    expires: string | null;
    /**
     *
     * OData Attributes:
     * |Attribute Name | Attribute Value |
     * | --- | ---|
     * | Name | `devices` |
     * | Type | `Collection(Jisc.Cloud.Assist.Models.Reports.CEDevice)` |
     */
    devices?: CeDevice[];
}

export interface EditableCeooddKey extends Pick<CeooddKey, "manufacturer" | "model">, Partial<Pick<CeooddKey, "ownership" | "released" | "expires">> {
}

export interface StaleDeviceKey extends DeviceReportEntry {
    /**
     *
     * OData Attributes:
     * |Attribute Name | Attribute Value |
     * | --- | ---|
     * | Name | `date` |
     * | Type | `Edm.DateTimeOffset` |
     * | Nullable | `false` |
     */
    date: string;
    /**
     *
     * OData Attributes:
     * |Attribute Name | Attribute Value |
     * | --- | ---|
     * | Name | `devices` |
     * | Type | `Collection(Jisc.Cloud.Assist.Models.Reports.CEDevice)` |
     */
    devices?: CeDevice[];
}

export interface EditableStaleDeviceKey extends Pick<StaleDeviceKey, "date"> {
}

export interface CeDevice extends DeviceReportEntry {
    /**
     *
     * OData Attributes:
     * |Attribute Name | Attribute Value |
     * | --- | ---|
     * | Name | `deviceId` |
     * | Type | `Edm.String` |
     * | Nullable | `false` |
     */
    deviceId: string;
    /**
     *
     * OData Attributes:
     * |Attribute Name | Attribute Value |
     * | --- | ---|
     * | Name | `displayName` |
     * | Type | `Edm.String` |
     * | Nullable | `false` |
     */
    displayName: string;
    /**
     *
     * OData Attributes:
     * |Attribute Name | Attribute Value |
     * | --- | ---|
     * | Name | `supportEnd` |
     * | Type | `Edm.String` |
     */
    supportEnd: string | null;
    /**
     *
     * OData Attributes:
     * |Attribute Name | Attribute Value |
     * | --- | ---|
     * | Name | `isEoL` |
     * | Type | `Edm.String` |
     */
    isEoL: string | null;
    /**
     *
     * OData Attributes:
     * |Attribute Name | Attribute Value |
     * | --- | ---|
     * | Name | `manufacturer` |
     * | Type | `Edm.String` |
     */
    manufacturer: string | null;
    /**
     *
     * OData Attributes:
     * |Attribute Name | Attribute Value |
     * | --- | ---|
     * | Name | `model` |
     * | Type | `Edm.String` |
     */
    model: string | null;
    /**
     *
     * OData Attributes:
     * |Attribute Name | Attribute Value |
     * | --- | ---|
     * | Name | `ownership` |
     * | Type | `Edm.String` |
     */
    ownership: string | null;
    /**
     *
     * OData Attributes:
     * |Attribute Name | Attribute Value |
     * | --- | ---|
     * | Name | `os` |
     * | Type | `Edm.String` |
     */
    os: string | null;
    /**
     *
     * OData Attributes:
     * |Attribute Name | Attribute Value |
     * | --- | ---|
     * | Name | `osVersion` |
     * | Type | `Edm.String` |
     */
    osVersion: string | null;
    /**
     *
     * OData Attributes:
     * |Attribute Name | Attribute Value |
     * | --- | ---|
     * | Name | `profileType` |
     * | Type | `Edm.String` |
     */
    profileType: string | null;
    /**
     *
     * OData Attributes:
     * |Attribute Name | Attribute Value |
     * | --- | ---|
     * | Name | `isRooted` |
     * | Type | `Edm.Boolean` |
     */
    isRooted: boolean | null;
    /**
     *
     * OData Attributes:
     * |Attribute Name | Attribute Value |
     * | --- | ---|
     * | Name | `approximateLastSignInDateTime` |
     * | Type | `Edm.String` |
     */
    approximateLastSignInDateTime: string | null;
    /**
     *
     * OData Attributes:
     * |Attribute Name | Attribute Value |
     * | --- | ---|
     * | Name | `managementType` |
     * | Type | `Edm.String` |
     */
    managementType: string | null;
    /**
     *
     * OData Attributes:
     * |Attribute Name | Attribute Value |
     * | --- | ---|
     * | Name | `released` |
     * | Type | `Edm.String` |
     */
    released: string | null;
    /**
     *
     * OData Attributes:
     * |Attribute Name | Attribute Value |
     * | --- | ---|
     * | Name | `registrationDateTime` |
     * | Type | `Edm.String` |
     */
    registrationDateTime: string | null;
    /**
     *
     * OData Attributes:
     * |Attribute Name | Attribute Value |
     * | --- | ---|
     * | Name | `trustType` |
     * | Type | `Edm.String` |
     */
    trustType: string | null;
    /**
     *
     * OData Attributes:
     * |Attribute Name | Attribute Value |
     * | --- | ---|
     * | Name | `enabled` |
     * | Type | `Edm.Boolean` |
     */
    enabled: boolean | null;
    /**
     *
     * OData Attributes:
     * |Attribute Name | Attribute Value |
     * | --- | ---|
     * | Name | `owners` |
     * | Type | `Collection(Jisc.Cloud.Assist.Models.Reports.DeviceOwner)` |
     */
    owners?: DeviceOwner[];
}

export interface EditableCeDevice extends Pick<CeDevice, "deviceId" | "displayName">, Partial<Pick<CeDevice, "supportEnd" | "isEoL" | "manufacturer" | "model" | "ownership" | "os" | "osVersion" | "profileType" | "isRooted" | "approximateLastSignInDateTime" | "managementType" | "released" | "registrationDateTime" | "trustType" | "enabled">> {
}

export interface DeviceOwner {
    /**
     * **Key Property**: This is a key property used to identify the entity.<br/>**Managed**: This property is managed on the server side and cannot be edited.
     *
     * OData Attributes:
     * |Attribute Name | Attribute Value |
     * | --- | ---|
     * | Name | `id` |
     * | Type | `Edm.String` |
     * | Nullable | `false` |
     */
    id: string;
    /**
     *
     * OData Attributes:
     * |Attribute Name | Attribute Value |
     * | --- | ---|
     * | Name | `displayName` |
     * | Type | `Edm.String` |
     * | Nullable | `false` |
     */
    displayName: string;
    /**
     *
     * OData Attributes:
     * |Attribute Name | Attribute Value |
     * | --- | ---|
     * | Name | `mail` |
     * | Type | `Edm.String` |
     * | Nullable | `false` |
     */
    mail: string;
    /**
     *
     * OData Attributes:
     * |Attribute Name | Attribute Value |
     * | --- | ---|
     * | Name | `department` |
     * | Type | `Edm.String` |
     * | Nullable | `false` |
     */
    department: string;
    /**
     *
     * OData Attributes:
     * |Attribute Name | Attribute Value |
     * | --- | ---|
     * | Name | `accountEnabled` |
     * | Type | `Edm.Boolean` |
     * | Nullable | `false` |
     */
    accountEnabled: boolean;
}

export type DeviceOwnerId = string | {id: string};

export interface EditableDeviceOwner extends Pick<DeviceOwner, "displayName" | "mail" | "department" | "accountEnabled"> {
}

export interface Feedback {
    /**
     *
     * OData Attributes:
     * |Attribute Name | Attribute Value |
     * | --- | ---|
     * | Name | `body` |
     * | Type | `Edm.String` |
     * | Nullable | `false` |
     */
    body: string;
    /**
     * **Managed**: This property is managed on the server side and cannot be edited.
     *
     * OData Attributes:
     * |Attribute Name | Attribute Value |
     * | --- | ---|
     * | Name | `created` |
     * | Type | `Edm.DateTimeOffset` |
     * | Nullable | `false` |
     */
    created: string;
    /**
     * **Key Property**: This is a key property used to identify the entity.<br/>**Managed**: This property is managed on the server side and cannot be edited.
     *
     * OData Attributes:
     * |Attribute Name | Attribute Value |
     * | --- | ---|
     * | Name | `id` |
     * | Type | `Edm.Guid` |
     * | Nullable | `false` |
     */
    id: string;
    /**
     *
     * OData Attributes:
     * |Attribute Name | Attribute Value |
     * | --- | ---|
     * | Name | `isAnonymous` |
     * | Type | `Edm.Boolean` |
     * | Nullable | `false` |
     */
    isAnonymous: boolean;
    /**
     * **Managed**: This property is managed on the server side and cannot be edited.
     *
     * OData Attributes:
     * |Attribute Name | Attribute Value |
     * | --- | ---|
     * | Name | `modified` |
     * | Type | `Edm.DateTimeOffset` |
     * | Nullable | `false` |
     */
    modified: string;
    /**
     *
     * OData Attributes:
     * |Attribute Name | Attribute Value |
     * | --- | ---|
     * | Name | `subject` |
     * | Type | `Edm.String` |
     * | Nullable | `false` |
     */
    subject: string;
    /**
     *
     * OData Attributes:
     * |Attribute Name | Attribute Value |
     * | --- | ---|
     * | Name | `state` |
     * | Type | `Jisc.Cloud.Assist.Models.FeedbackModels.FeedbackState` |
     * | Nullable | `false` |
     */
    state: FeedbackState;
    /**
     *
     * OData Attributes:
     * |Attribute Name | Attribute Value |
     * | --- | ---|
     * | Name | `type` |
     * | Type | `Jisc.Cloud.Assist.Models.FeedbackModels.FeedbackType` |
     * | Nullable | `false` |
     */
    type: FeedbackType;
    /**
     *
     * OData Attributes:
     * |Attribute Name | Attribute Value |
     * | --- | ---|
     * | Name | `user` |
     * | Type | `Edm.Guid` |
     * | Nullable | `false` |
     */
    user: string;
    /**
     *
     * OData Attributes:
     * |Attribute Name | Attribute Value |
     * | --- | ---|
     * | Name | `userDisplayName` |
     * | Type | `Edm.String` |
     * | Nullable | `false` |
     */
    userDisplayName: string;
    /**
     *
     * OData Attributes:
     * |Attribute Name | Attribute Value |
     * | --- | ---|
     * | Name | `comments` |
     * | Type | `Collection(Jisc.Cloud.Assist.Models.FeedbackModels.FeedbackComment)` |
     */
    comments?: FeedbackComment[];
    /**
     *
     * OData Attributes:
     * |Attribute Name | Attribute Value |
     * | --- | ---|
     * | Name | `downVotes` |
     * | Type | `Collection(Jisc.Cloud.Assist.Models.FeedbackModels.FeedbackVote)` |
     */
    downVotes?: FeedbackVote[];
    /**
     *
     * OData Attributes:
     * |Attribute Name | Attribute Value |
     * | --- | ---|
     * | Name | `upVotes` |
     * | Type | `Collection(Jisc.Cloud.Assist.Models.FeedbackModels.FeedbackVote)` |
     */
    upVotes?: FeedbackVote[];
}

export type FeedbackId = string | {id: string};

export interface EditableFeedback extends Pick<Feedback, "body" | "isAnonymous" | "subject" | "state" | "type" | "user" | "userDisplayName"> {
}

export interface FeedbackComment {
    /**
     *
     * OData Attributes:
     * |Attribute Name | Attribute Value |
     * | --- | ---|
     * | Name | `body` |
     * | Type | `Edm.String` |
     * | Nullable | `false` |
     */
    body: string;
    /**
     * **Managed**: This property is managed on the server side and cannot be edited.
     *
     * OData Attributes:
     * |Attribute Name | Attribute Value |
     * | --- | ---|
     * | Name | `created` |
     * | Type | `Edm.DateTimeOffset` |
     * | Nullable | `false` |
     */
    created: string;
    /**
     * **Key Property**: This is a key property used to identify the entity.<br/>**Managed**: This property is managed on the server side and cannot be edited.
     *
     * OData Attributes:
     * |Attribute Name | Attribute Value |
     * | --- | ---|
     * | Name | `id` |
     * | Type | `Edm.Guid` |
     * | Nullable | `false` |
     */
    id: string;
    /**
     *
     * OData Attributes:
     * |Attribute Name | Attribute Value |
     * | --- | ---|
     * | Name | `isAnonymous` |
     * | Type | `Edm.Boolean` |
     * | Nullable | `false` |
     */
    isAnonymous: boolean;
    /**
     *
     * OData Attributes:
     * |Attribute Name | Attribute Value |
     * | --- | ---|
     * | Name | `isJisc` |
     * | Type | `Edm.Boolean` |
     * | Nullable | `false` |
     */
    isJisc: boolean;
    /**
     * **Managed**: This property is managed on the server side and cannot be edited.
     *
     * OData Attributes:
     * |Attribute Name | Attribute Value |
     * | --- | ---|
     * | Name | `modified` |
     * | Type | `Edm.DateTimeOffset` |
     * | Nullable | `false` |
     */
    modified: string;
    /**
     *
     * OData Attributes:
     * |Attribute Name | Attribute Value |
     * | --- | ---|
     * | Name | `user` |
     * | Type | `Edm.Guid` |
     * | Nullable | `false` |
     */
    user: string;
    /**
     *
     * OData Attributes:
     * |Attribute Name | Attribute Value |
     * | --- | ---|
     * | Name | `userDisplayName` |
     * | Type | `Edm.String` |
     * | Nullable | `false` |
     */
    userDisplayName: string;
}

export type FeedbackCommentId = string | {id: string};

export interface EditableFeedbackComment extends Pick<FeedbackComment, "body" | "isAnonymous" | "isJisc" | "user" | "userDisplayName"> {
}

export interface FeedbackVote {
    /**
     * **Managed**: This property is managed on the server side and cannot be edited.
     *
     * OData Attributes:
     * |Attribute Name | Attribute Value |
     * | --- | ---|
     * | Name | `created` |
     * | Type | `Edm.DateTimeOffset` |
     * | Nullable | `false` |
     */
    created: string;
    /**
     * **Key Property**: This is a key property used to identify the entity.<br/>**Managed**: This property is managed on the server side and cannot be edited.
     *
     * OData Attributes:
     * |Attribute Name | Attribute Value |
     * | --- | ---|
     * | Name | `id` |
     * | Type | `Edm.Guid` |
     * | Nullable | `false` |
     */
    id: string;
    /**
     *
     * OData Attributes:
     * |Attribute Name | Attribute Value |
     * | --- | ---|
     * | Name | `isAnonymous` |
     * | Type | `Edm.Boolean` |
     * | Nullable | `false` |
     */
    isAnonymous: boolean;
}

export type FeedbackVoteId = string | {id: string};

export interface EditableFeedbackVote extends Pick<FeedbackVote, "isAnonymous"> {
}

export interface RefreshCycle {
    /**
     *
     * OData Attributes:
     * |Attribute Name | Attribute Value |
     * | --- | ---|
     * | Name | `start` |
     * | Type | `Edm.DateTimeOffset` |
     * | Nullable | `false` |
     */
    start: string;
    /**
     *
     * OData Attributes:
     * |Attribute Name | Attribute Value |
     * | --- | ---|
     * | Name | `repeat` |
     * | Type | `Edm.String` |
     * | Nullable | `false` |
     */
    repeat: string;
}

export interface EditableRefreshCycle extends Pick<RefreshCycle, "start" | "repeat"> {
}

export interface OsBuild {
    /**
     *
     * OData Attributes:
     * |Attribute Name | Attribute Value |
     * | --- | ---|
     * | Name | `build` |
     * | Type | `Edm.String` |
     * | Nullable | `false` |
     */
    build: string;
    /**
     *
     * OData Attributes:
     * |Attribute Name | Attribute Value |
     * | --- | ---|
     * | Name | `released` |
     * | Type | `Edm.DateTimeOffset` |
     */
    released: string | null;
    /**
     *
     * OData Attributes:
     * |Attribute Name | Attribute Value |
     * | --- | ---|
     * | Name | `expires` |
     * | Type | `Edm.DateTimeOffset` |
     */
    expires: string | null;
}

export interface EditableOsBuild extends Pick<OsBuild, "build">, Partial<Pick<OsBuild, "released" | "expires">> {
}

export interface LookupPolicy {
    /**
     * **Managed**: This property is managed on the server side and cannot be edited.
     *
     * OData Attributes:
     * |Attribute Name | Attribute Value |
     * | --- | ---|
     * | Name | `id` |
     * | Type | `Edm.String` |
     * | Nullable | `false` |
     */
    id: string;
    /**
     *
     * OData Attributes:
     * |Attribute Name | Attribute Value |
     * | --- | ---|
     * | Name | `displayName` |
     * | Type | `Edm.String` |
     * | Nullable | `false` |
     */
    displayName: string;
    /**
     *
     * OData Attributes:
     * |Attribute Name | Attribute Value |
     * | --- | ---|
     * | Name | `type` |
     * | Type | `Jisc.Cloud.Assist.Models.TenantModels.PolicyType` |
     * | Nullable | `false` |
     */
    type: PolicyType;
}

export interface EditableLookupPolicy extends Pick<LookupPolicy, "displayName" | "type"> {
}

export interface HcIgnore {
    /**
     *
     * OData Attributes:
     * |Attribute Name | Attribute Value |
     * | --- | ---|
     * | Name | `healthCheckId` |
     * | Type | `Edm.Guid` |
     * | Nullable | `false` |
     */
    healthCheckId: string;
    /**
     *
     * OData Attributes:
     * |Attribute Name | Attribute Value |
     * | --- | ---|
     * | Name | `note` |
     * | Type | `Edm.String` |
     * | Nullable | `false` |
     */
    note: string;
    /**
     * **Managed**: This property is managed on the server side and cannot be edited.
     *
     * OData Attributes:
     * |Attribute Name | Attribute Value |
     * | --- | ---|
     * | Name | `createdBy` |
     * | Type | `Edm.String` |
     */
    createdBy: string | null;
    /**
     *
     * OData Attributes:
     * |Attribute Name | Attribute Value |
     * | --- | ---|
     * | Name | `createdOn` |
     * | Type | `Edm.DateTimeOffset` |
     */
    createdOn: string | null;
}

export interface EditableHcIgnore extends Pick<HcIgnore, "healthCheckId" | "note">, Partial<Pick<HcIgnore, "createdOn">> {
}

export interface AttributeMapping {
    /**
     *
     * OData Attributes:
     * |Attribute Name | Attribute Value |
     * | --- | ---|
     * | Name | `manufacturer` |
     * | Type | `Edm.Int32` |
     */
    manufacturer: number | null;
    /**
     *
     * OData Attributes:
     * |Attribute Name | Attribute Value |
     * | --- | ---|
     * | Name | `model` |
     * | Type | `Edm.Int32` |
     */
    model: number | null;
    /**
     *
     * OData Attributes:
     * |Attribute Name | Attribute Value |
     * | --- | ---|
     * | Name | `released` |
     * | Type | `Edm.Int32` |
     */
    released: number | null;
    /**
     *
     * OData Attributes:
     * |Attribute Name | Attribute Value |
     * | --- | ---|
     * | Name | `supportEnd` |
     * | Type | `Edm.Int32` |
     */
    supportEnd: number | null;
    /**
     *
     * OData Attributes:
     * |Attribute Name | Attribute Value |
     * | --- | ---|
     * | Name | `isEoL` |
     * | Type | `Edm.Int32` |
     */
    isEoL: number | null;
}

export interface EditableAttributeMapping extends Partial<Pick<AttributeMapping, "manufacturer" | "model" | "released" | "supportEnd" | "isEoL">> {
}

export interface RefreshedWrappedArray_1OfDeviceReportEntry {
    /**
     *
     * OData Attributes:
     * |Attribute Name | Attribute Value |
     * | --- | ---|
     * | Name | `refreshed` |
     * | Type | `Edm.DateTimeOffset` |
     * | Nullable | `false` |
     */
    refreshed: string;
    /**
     *
     * OData Attributes:
     * |Attribute Name | Attribute Value |
     * | --- | ---|
     * | Name | `value` |
     * | Type | `Collection(Jisc.Cloud.Assist.Models.Reports.DeviceReportEntry)` |
     */
    value?: DeviceReportEntry[];
}

export interface EditableRefreshedWrappedArray_1OfDeviceReportEntry extends Pick<RefreshedWrappedArray_1OfDeviceReportEntry, "refreshed"> {
}

export interface ActionResult {
}

export interface EditableActionResult {
}

export interface ChallengeResult extends ActionResult {
    /**
     *
     * OData Attributes:
     * |Attribute Name | Attribute Value |
     * | --- | ---|
     * | Name | `authenticationSchemes` |
     * | Type | `Collection(Edm.String)` |
     */
    authenticationSchemes: string[];
    /**
     *
     * OData Attributes:
     * |Attribute Name | Attribute Value |
     * | --- | ---|
     * | Name | `properties` |
     * | Type | `Microsoft.AspNetCore.Authentication.AuthenticationProperties` |
     */
    properties: AuthenticationProperties | null;
}

export interface EditableChallengeResult extends Partial<Pick<ChallengeResult, "authenticationSchemes">> {
    properties?: EditableAuthenticationProperties | null;
}

export interface ContentResult extends ActionResult {
    /**
     *
     * OData Attributes:
     * |Attribute Name | Attribute Value |
     * | --- | ---|
     * | Name | `content` |
     * | Type | `Edm.String` |
     */
    content: string | null;
    /**
     *
     * OData Attributes:
     * |Attribute Name | Attribute Value |
     * | --- | ---|
     * | Name | `contentType` |
     * | Type | `Edm.String` |
     */
    contentType: string | null;
    /**
     *
     * OData Attributes:
     * |Attribute Name | Attribute Value |
     * | --- | ---|
     * | Name | `statusCode` |
     * | Type | `Edm.Int32` |
     */
    statusCode: number | null;
}

export interface EditableContentResult extends Partial<Pick<ContentResult, "content" | "contentType" | "statusCode">> {
}

export interface EmptyResult extends ActionResult {
}

export interface EditableEmptyResult {
}

export interface FileResult extends ActionResult {
    /**
     *
     * OData Attributes:
     * |Attribute Name | Attribute Value |
     * | --- | ---|
     * | Name | `fileDownloadName` |
     * | Type | `Edm.String` |
     * | Nullable | `false` |
     */
    fileDownloadName: string;
    /**
     *
     * OData Attributes:
     * |Attribute Name | Attribute Value |
     * | --- | ---|
     * | Name | `lastModified` |
     * | Type | `Edm.DateTimeOffset` |
     */
    lastModified: string | null;
    /**
     *
     * OData Attributes:
     * |Attribute Name | Attribute Value |
     * | --- | ---|
     * | Name | `entityTag` |
     * | Type | `Microsoft.Net.Http.Headers.EntityTagHeaderValue` |
     */
    entityTag: EntityTagHeaderValue | null;
    /**
     *
     * OData Attributes:
     * |Attribute Name | Attribute Value |
     * | --- | ---|
     * | Name | `enableRangeProcessing` |
     * | Type | `Edm.Boolean` |
     * | Nullable | `false` |
     */
    enableRangeProcessing: boolean;
}

export interface EditableFileResult extends Pick<FileResult, "fileDownloadName" | "enableRangeProcessing">, Partial<Pick<FileResult, "lastModified">> {
    entityTag?: EditableEntityTagHeaderValue | null;
}

export interface FileContentResult extends FileResult {
    /**
     *
     * OData Attributes:
     * |Attribute Name | Attribute Value |
     * | --- | ---|
     * | Name | `fileContents` |
     * | Type | `Edm.Binary` |
     * | Nullable | `false` |
     */
    fileContents: string;
}

export interface EditableFileContentResult extends Pick<FileContentResult, "fileDownloadName" | "enableRangeProcessing" | "fileContents">, Partial<Pick<FileContentResult, "lastModified">> {
    entityTag?: EditableEntityTagHeaderValue | null;
}

export interface FileStreamResult extends FileResult {
    /**
     *
     * OData Attributes:
     * |Attribute Name | Attribute Value |
     * | --- | ---|
     * | Name | `fileStream` |
     * | Type | `Edm.Stream` |
     * | Nullable | `false` |
     */
    fileStream: string;
}

export interface EditableFileStreamResult extends Pick<FileStreamResult, "fileDownloadName" | "enableRangeProcessing" | "fileStream">, Partial<Pick<FileStreamResult, "lastModified">> {
    entityTag?: EditableEntityTagHeaderValue | null;
}

export interface PhysicalFileResult extends FileResult {
    /**
     *
     * OData Attributes:
     * |Attribute Name | Attribute Value |
     * | --- | ---|
     * | Name | `fileName` |
     * | Type | `Edm.String` |
     * | Nullable | `false` |
     */
    fileName: string;
}

export interface EditablePhysicalFileResult extends Pick<PhysicalFileResult, "fileDownloadName" | "enableRangeProcessing" | "fileName">, Partial<Pick<PhysicalFileResult, "lastModified">> {
    entityTag?: EditableEntityTagHeaderValue | null;
}

export interface VirtualFileResult extends FileResult {
    /**
     *
     * OData Attributes:
     * |Attribute Name | Attribute Value |
     * | --- | ---|
     * | Name | `fileName` |
     * | Type | `Edm.String` |
     * | Nullable | `false` |
     */
    fileName: string;
    /**
     *
     * OData Attributes:
     * |Attribute Name | Attribute Value |
     * | --- | ---|
     * | Name | `fileProvider` |
     * | Type | `Microsoft.Extensions.FileProviders.IFileProvider` |
     */
    fileProvider: IFileProvider | null;
}

export interface EditableVirtualFileResult extends Pick<VirtualFileResult, "fileDownloadName" | "enableRangeProcessing" | "fileName">, Partial<Pick<VirtualFileResult, "lastModified">> {
    entityTag?: EditableEntityTagHeaderValue | null;
    fileProvider?: EditableIFileProvider | null;
}

export interface ForbidResult extends ActionResult {
    /**
     *
     * OData Attributes:
     * |Attribute Name | Attribute Value |
     * | --- | ---|
     * | Name | `authenticationSchemes` |
     * | Type | `Collection(Edm.String)` |
     */
    authenticationSchemes: string[];
    /**
     *
     * OData Attributes:
     * |Attribute Name | Attribute Value |
     * | --- | ---|
     * | Name | `properties` |
     * | Type | `Microsoft.AspNetCore.Authentication.AuthenticationProperties` |
     */
    properties: AuthenticationProperties | null;
}

export interface EditableForbidResult extends Partial<Pick<ForbidResult, "authenticationSchemes">> {
    properties?: EditableAuthenticationProperties | null;
}

export interface JsonResult extends ActionResult {
    /**
     *
     * OData Attributes:
     * |Attribute Name | Attribute Value |
     * | --- | ---|
     * | Name | `contentType` |
     * | Type | `Edm.String` |
     */
    contentType: string | null;
    /**
     *
     * OData Attributes:
     * |Attribute Name | Attribute Value |
     * | --- | ---|
     * | Name | `serializerSettings` |
     * | Type | `Edm.Untyped` |
     */
    serializerSettings: string | null;
    /**
     *
     * OData Attributes:
     * |Attribute Name | Attribute Value |
     * | --- | ---|
     * | Name | `statusCode` |
     * | Type | `Edm.Int32` |
     */
    statusCode: number | null;
    /**
     *
     * OData Attributes:
     * |Attribute Name | Attribute Value |
     * | --- | ---|
     * | Name | `value` |
     * | Type | `Edm.Untyped` |
     */
    value: string | null;
}

export interface EditableJsonResult extends Partial<Pick<JsonResult, "contentType" | "serializerSettings" | "statusCode" | "value">> {
}

export interface LocalRedirectResult extends ActionResult {
    /**
     *
     * OData Attributes:
     * |Attribute Name | Attribute Value |
     * | --- | ---|
     * | Name | `permanent` |
     * | Type | `Edm.Boolean` |
     * | Nullable | `false` |
     */
    permanent: boolean;
    /**
     *
     * OData Attributes:
     * |Attribute Name | Attribute Value |
     * | --- | ---|
     * | Name | `preserveMethod` |
     * | Type | `Edm.Boolean` |
     * | Nullable | `false` |
     */
    preserveMethod: boolean;
    /**
     *
     * OData Attributes:
     * |Attribute Name | Attribute Value |
     * | --- | ---|
     * | Name | `url` |
     * | Type | `Edm.String` |
     * | Nullable | `false` |
     */
    url: string;
    /**
     *
     * OData Attributes:
     * |Attribute Name | Attribute Value |
     * | --- | ---|
     * | Name | `urlHelper` |
     * | Type | `Microsoft.AspNetCore.Mvc.IUrlHelper` |
     */
    urlHelper: IUrlHelper | null;
}

export interface EditableLocalRedirectResult extends Pick<LocalRedirectResult, "permanent" | "preserveMethod" | "url"> {
    urlHelper?: EditableIUrlHelper | null;
}

export interface IUrlHelper {
}

export interface EditableIUrlHelper {
}

export interface ObjectResult extends ActionResult {
    /**
     *
     * OData Attributes:
     * |Attribute Name | Attribute Value |
     * | --- | ---|
     * | Name | `value` |
     * | Type | `Edm.Untyped` |
     */
    value: string | null;
    /**
     *
     * OData Attributes:
     * |Attribute Name | Attribute Value |
     * | --- | ---|
     * | Name | `formatters` |
     * | Type | `Collection(Microsoft.AspNetCore.Mvc.Formatters.IOutputFormatter)` |
     */
    formatters: IOutputFormatter[];
    /**
     *
     * OData Attributes:
     * |Attribute Name | Attribute Value |
     * | --- | ---|
     * | Name | `contentTypes` |
     * | Type | `Collection(Edm.String)` |
     */
    contentTypes: string[];
    /**
     *
     * OData Attributes:
     * |Attribute Name | Attribute Value |
     * | --- | ---|
     * | Name | `declaredType` |
     * | Type | `System.Type` |
     */
    declaredType: Type | null;
    /**
     *
     * OData Attributes:
     * |Attribute Name | Attribute Value |
     * | --- | ---|
     * | Name | `statusCode` |
     * | Type | `Edm.Int32` |
     */
    statusCode: number | null;
}

export interface EditableObjectResult extends Partial<Pick<ObjectResult, "value" | "contentTypes" | "statusCode">> {
    formatters?: EditableIOutputFormatter[];
    declaredType?: EditableType | null;
}

export interface AcceptedAtActionResult extends ObjectResult {
    /**
     *
     * OData Attributes:
     * |Attribute Name | Attribute Value |
     * | --- | ---|
     * | Name | `urlHelper` |
     * | Type | `Microsoft.AspNetCore.Mvc.IUrlHelper` |
     */
    urlHelper: IUrlHelper | null;
    /**
     *
     * OData Attributes:
     * |Attribute Name | Attribute Value |
     * | --- | ---|
     * | Name | `actionName` |
     * | Type | `Edm.String` |
     */
    actionName: string | null;
    /**
     *
     * OData Attributes:
     * |Attribute Name | Attribute Value |
     * | --- | ---|
     * | Name | `controllerName` |
     * | Type | `Edm.String` |
     */
    controllerName: string | null;
}

export interface EditableAcceptedAtActionResult extends Partial<Pick<AcceptedAtActionResult, "value" | "contentTypes" | "statusCode" | "actionName" | "controllerName">> {
    formatters?: EditableIOutputFormatter[];
    declaredType?: EditableType | null;
    urlHelper?: EditableIUrlHelper | null;
}

export interface AcceptedAtRouteResult extends ObjectResult {
    /**
     *
     * OData Attributes:
     * |Attribute Name | Attribute Value |
     * | --- | ---|
     * | Name | `urlHelper` |
     * | Type | `Microsoft.AspNetCore.Mvc.IUrlHelper` |
     */
    urlHelper: IUrlHelper | null;
    /**
     *
     * OData Attributes:
     * |Attribute Name | Attribute Value |
     * | --- | ---|
     * | Name | `routeName` |
     * | Type | `Edm.String` |
     */
    routeName: string | null;
}

export interface EditableAcceptedAtRouteResult extends Partial<Pick<AcceptedAtRouteResult, "value" | "contentTypes" | "statusCode" | "routeName">> {
    formatters?: EditableIOutputFormatter[];
    declaredType?: EditableType | null;
    urlHelper?: EditableIUrlHelper | null;
}

export interface AcceptedResult extends ObjectResult {
    /**
     *
     * OData Attributes:
     * |Attribute Name | Attribute Value |
     * | --- | ---|
     * | Name | `location` |
     * | Type | `Edm.String` |
     */
    location: string | null;
}

export interface EditableAcceptedResult extends Partial<Pick<AcceptedResult, "value" | "contentTypes" | "statusCode" | "location">> {
    formatters?: EditableIOutputFormatter[];
    declaredType?: EditableType | null;
}

export interface BadRequestObjectResult extends ObjectResult {
}

export interface EditableBadRequestObjectResult extends Partial<Pick<BadRequestObjectResult, "value" | "contentTypes" | "statusCode">> {
    formatters?: EditableIOutputFormatter[];
    declaredType?: EditableType | null;
}

export interface ConflictObjectResult extends ObjectResult {
}

export interface EditableConflictObjectResult extends Partial<Pick<ConflictObjectResult, "value" | "contentTypes" | "statusCode">> {
    formatters?: EditableIOutputFormatter[];
    declaredType?: EditableType | null;
}

export interface CreatedAtActionResult extends ObjectResult {
    /**
     *
     * OData Attributes:
     * |Attribute Name | Attribute Value |
     * | --- | ---|
     * | Name | `urlHelper` |
     * | Type | `Microsoft.AspNetCore.Mvc.IUrlHelper` |
     */
    urlHelper: IUrlHelper | null;
    /**
     *
     * OData Attributes:
     * |Attribute Name | Attribute Value |
     * | --- | ---|
     * | Name | `actionName` |
     * | Type | `Edm.String` |
     */
    actionName: string | null;
    /**
     *
     * OData Attributes:
     * |Attribute Name | Attribute Value |
     * | --- | ---|
     * | Name | `controllerName` |
     * | Type | `Edm.String` |
     */
    controllerName: string | null;
}

export interface EditableCreatedAtActionResult extends Partial<Pick<CreatedAtActionResult, "value" | "contentTypes" | "statusCode" | "actionName" | "controllerName">> {
    formatters?: EditableIOutputFormatter[];
    declaredType?: EditableType | null;
    urlHelper?: EditableIUrlHelper | null;
}

export interface CreatedAtRouteResult extends ObjectResult {
    /**
     *
     * OData Attributes:
     * |Attribute Name | Attribute Value |
     * | --- | ---|
     * | Name | `urlHelper` |
     * | Type | `Microsoft.AspNetCore.Mvc.IUrlHelper` |
     */
    urlHelper: IUrlHelper | null;
    /**
     *
     * OData Attributes:
     * |Attribute Name | Attribute Value |
     * | --- | ---|
     * | Name | `routeName` |
     * | Type | `Edm.String` |
     */
    routeName: string | null;
}

export interface EditableCreatedAtRouteResult extends Partial<Pick<CreatedAtRouteResult, "value" | "contentTypes" | "statusCode" | "routeName">> {
    formatters?: EditableIOutputFormatter[];
    declaredType?: EditableType | null;
    urlHelper?: EditableIUrlHelper | null;
}

export interface CreatedResult extends ObjectResult {
    /**
     *
     * OData Attributes:
     * |Attribute Name | Attribute Value |
     * | --- | ---|
     * | Name | `location` |
     * | Type | `Edm.String` |
     */
    location: string | null;
}

export interface EditableCreatedResult extends Partial<Pick<CreatedResult, "value" | "contentTypes" | "statusCode" | "location">> {
    formatters?: EditableIOutputFormatter[];
    declaredType?: EditableType | null;
}

export interface NotFoundObjectResult extends ObjectResult {
}

export interface EditableNotFoundObjectResult extends Partial<Pick<NotFoundObjectResult, "value" | "contentTypes" | "statusCode">> {
    formatters?: EditableIOutputFormatter[];
    declaredType?: EditableType | null;
}

export interface OkObjectResult extends ObjectResult {
}

export interface EditableOkObjectResult extends Partial<Pick<OkObjectResult, "value" | "contentTypes" | "statusCode">> {
    formatters?: EditableIOutputFormatter[];
    declaredType?: EditableType | null;
}

export interface UnauthorizedObjectResult extends ObjectResult {
}

export interface EditableUnauthorizedObjectResult extends Partial<Pick<UnauthorizedObjectResult, "value" | "contentTypes" | "statusCode">> {
    formatters?: EditableIOutputFormatter[];
    declaredType?: EditableType | null;
}

export interface UnprocessableEntityObjectResult extends ObjectResult {
}

export interface EditableUnprocessableEntityObjectResult extends Partial<Pick<UnprocessableEntityObjectResult, "value" | "contentTypes" | "statusCode">> {
    formatters?: EditableIOutputFormatter[];
    declaredType?: EditableType | null;
}

export interface RedirectResult extends ActionResult {
    /**
     *
     * OData Attributes:
     * |Attribute Name | Attribute Value |
     * | --- | ---|
     * | Name | `permanent` |
     * | Type | `Edm.Boolean` |
     * | Nullable | `false` |
     */
    permanent: boolean;
    /**
     *
     * OData Attributes:
     * |Attribute Name | Attribute Value |
     * | --- | ---|
     * | Name | `preserveMethod` |
     * | Type | `Edm.Boolean` |
     * | Nullable | `false` |
     */
    preserveMethod: boolean;
    /**
     *
     * OData Attributes:
     * |Attribute Name | Attribute Value |
     * | --- | ---|
     * | Name | `url` |
     * | Type | `Edm.String` |
     * | Nullable | `false` |
     */
    url: string;
    /**
     *
     * OData Attributes:
     * |Attribute Name | Attribute Value |
     * | --- | ---|
     * | Name | `urlHelper` |
     * | Type | `Microsoft.AspNetCore.Mvc.IUrlHelper` |
     */
    urlHelper: IUrlHelper | null;
}

export interface EditableRedirectResult extends Pick<RedirectResult, "permanent" | "preserveMethod" | "url"> {
    urlHelper?: EditableIUrlHelper | null;
}

export interface RedirectToActionResult extends ActionResult {
    /**
     *
     * OData Attributes:
     * |Attribute Name | Attribute Value |
     * | --- | ---|
     * | Name | `urlHelper` |
     * | Type | `Microsoft.AspNetCore.Mvc.IUrlHelper` |
     */
    urlHelper: IUrlHelper | null;
    /**
     *
     * OData Attributes:
     * |Attribute Name | Attribute Value |
     * | --- | ---|
     * | Name | `actionName` |
     * | Type | `Edm.String` |
     */
    actionName: string | null;
    /**
     *
     * OData Attributes:
     * |Attribute Name | Attribute Value |
     * | --- | ---|
     * | Name | `controllerName` |
     * | Type | `Edm.String` |
     */
    controllerName: string | null;
    /**
     *
     * OData Attributes:
     * |Attribute Name | Attribute Value |
     * | --- | ---|
     * | Name | `permanent` |
     * | Type | `Edm.Boolean` |
     * | Nullable | `false` |
     */
    permanent: boolean;
    /**
     *
     * OData Attributes:
     * |Attribute Name | Attribute Value |
     * | --- | ---|
     * | Name | `preserveMethod` |
     * | Type | `Edm.Boolean` |
     * | Nullable | `false` |
     */
    preserveMethod: boolean;
    /**
     *
     * OData Attributes:
     * |Attribute Name | Attribute Value |
     * | --- | ---|
     * | Name | `fragment` |
     * | Type | `Edm.String` |
     */
    fragment: string | null;
}

export interface EditableRedirectToActionResult extends Pick<RedirectToActionResult, "permanent" | "preserveMethod">, Partial<Pick<RedirectToActionResult, "actionName" | "controllerName" | "fragment">> {
    urlHelper?: EditableIUrlHelper | null;
}

export interface RedirectToPageResult extends ActionResult {
    /**
     *
     * OData Attributes:
     * |Attribute Name | Attribute Value |
     * | --- | ---|
     * | Name | `urlHelper` |
     * | Type | `Microsoft.AspNetCore.Mvc.IUrlHelper` |
     */
    urlHelper: IUrlHelper | null;
    /**
     *
     * OData Attributes:
     * |Attribute Name | Attribute Value |
     * | --- | ---|
     * | Name | `pageName` |
     * | Type | `Edm.String` |
     */
    pageName: string | null;
    /**
     *
     * OData Attributes:
     * |Attribute Name | Attribute Value |
     * | --- | ---|
     * | Name | `pageHandler` |
     * | Type | `Edm.String` |
     */
    pageHandler: string | null;
    /**
     *
     * OData Attributes:
     * |Attribute Name | Attribute Value |
     * | --- | ---|
     * | Name | `permanent` |
     * | Type | `Edm.Boolean` |
     * | Nullable | `false` |
     */
    permanent: boolean;
    /**
     *
     * OData Attributes:
     * |Attribute Name | Attribute Value |
     * | --- | ---|
     * | Name | `preserveMethod` |
     * | Type | `Edm.Boolean` |
     * | Nullable | `false` |
     */
    preserveMethod: boolean;
    /**
     *
     * OData Attributes:
     * |Attribute Name | Attribute Value |
     * | --- | ---|
     * | Name | `fragment` |
     * | Type | `Edm.String` |
     */
    fragment: string | null;
    /**
     *
     * OData Attributes:
     * |Attribute Name | Attribute Value |
     * | --- | ---|
     * | Name | `protocol` |
     * | Type | `Edm.String` |
     */
    protocol: string | null;
    /**
     *
     * OData Attributes:
     * |Attribute Name | Attribute Value |
     * | --- | ---|
     * | Name | `host` |
     * | Type | `Edm.String` |
     */
    host: string | null;
}

export interface EditableRedirectToPageResult extends Pick<RedirectToPageResult, "permanent" | "preserveMethod">, Partial<Pick<RedirectToPageResult, "pageName" | "pageHandler" | "fragment" | "protocol" | "host">> {
    urlHelper?: EditableIUrlHelper | null;
}

export interface RedirectToRouteResult extends ActionResult {
    /**
     *
     * OData Attributes:
     * |Attribute Name | Attribute Value |
     * | --- | ---|
     * | Name | `urlHelper` |
     * | Type | `Microsoft.AspNetCore.Mvc.IUrlHelper` |
     */
    urlHelper: IUrlHelper | null;
    /**
     *
     * OData Attributes:
     * |Attribute Name | Attribute Value |
     * | --- | ---|
     * | Name | `routeName` |
     * | Type | `Edm.String` |
     */
    routeName: string | null;
    /**
     *
     * OData Attributes:
     * |Attribute Name | Attribute Value |
     * | --- | ---|
     * | Name | `permanent` |
     * | Type | `Edm.Boolean` |
     * | Nullable | `false` |
     */
    permanent: boolean;
    /**
     *
     * OData Attributes:
     * |Attribute Name | Attribute Value |
     * | --- | ---|
     * | Name | `preserveMethod` |
     * | Type | `Edm.Boolean` |
     * | Nullable | `false` |
     */
    preserveMethod: boolean;
    /**
     *
     * OData Attributes:
     * |Attribute Name | Attribute Value |
     * | --- | ---|
     * | Name | `fragment` |
     * | Type | `Edm.String` |
     */
    fragment: string | null;
}

export interface EditableRedirectToRouteResult extends Pick<RedirectToRouteResult, "permanent" | "preserveMethod">, Partial<Pick<RedirectToRouteResult, "routeName" | "fragment">> {
    urlHelper?: EditableIUrlHelper | null;
}

export interface SignInResult extends ActionResult {
    /**
     *
     * OData Attributes:
     * |Attribute Name | Attribute Value |
     * | --- | ---|
     * | Name | `authenticationScheme` |
     * | Type | `Edm.String` |
     */
    authenticationScheme: string | null;
    /**
     *
     * OData Attributes:
     * |Attribute Name | Attribute Value |
     * | --- | ---|
     * | Name | `principal` |
     * | Type | `System.Security.Claims.ClaimsPrincipal` |
     * | Nullable | `false` |
     */
    principal: ClaimsPrincipal;
    /**
     *
     * OData Attributes:
     * |Attribute Name | Attribute Value |
     * | --- | ---|
     * | Name | `properties` |
     * | Type | `Microsoft.AspNetCore.Authentication.AuthenticationProperties` |
     */
    properties: AuthenticationProperties | null;
}

export interface EditableSignInResult extends Partial<Pick<SignInResult, "authenticationScheme">> {
    principal: EditableClaimsPrincipal;
    properties?: EditableAuthenticationProperties | null;
}

export interface SignOutResult extends ActionResult {
    /**
     *
     * OData Attributes:
     * |Attribute Name | Attribute Value |
     * | --- | ---|
     * | Name | `authenticationSchemes` |
     * | Type | `Collection(Edm.String)` |
     */
    authenticationSchemes: string[];
    /**
     *
     * OData Attributes:
     * |Attribute Name | Attribute Value |
     * | --- | ---|
     * | Name | `properties` |
     * | Type | `Microsoft.AspNetCore.Authentication.AuthenticationProperties` |
     */
    properties: AuthenticationProperties | null;
}

export interface EditableSignOutResult extends Partial<Pick<SignOutResult, "authenticationSchemes">> {
    properties?: EditableAuthenticationProperties | null;
}

export interface StatusCodeResult extends ActionResult {
}

export interface EditableStatusCodeResult {
}

export interface BadRequestResult extends StatusCodeResult {
}

export interface EditableBadRequestResult {
}

export interface AntiforgeryValidationFailedResult extends BadRequestResult {
}

export interface EditableAntiforgeryValidationFailedResult {
}

export interface ConflictResult extends StatusCodeResult {
}

export interface EditableConflictResult {
}

export interface NoContentResult extends StatusCodeResult {
}

export interface EditableNoContentResult {
}

export interface NotFoundResult extends StatusCodeResult {
}

export interface EditableNotFoundResult {
}

export interface OkResult extends StatusCodeResult {
}

export interface EditableOkResult {
}

export interface UnauthorizedResult extends StatusCodeResult {
}

export interface EditableUnauthorizedResult {
}

export interface UnprocessableEntityResult extends StatusCodeResult {
}

export interface EditableUnprocessableEntityResult {
}

export interface UnsupportedMediaTypeResult extends StatusCodeResult {
}

export interface EditableUnsupportedMediaTypeResult {
}

export interface AuthenticationProperties {
    /**
     *
     * OData Attributes:
     * |Attribute Name | Attribute Value |
     * | --- | ---|
     * | Name | `items` |
     * | Type | `Collection(System.Collections.Generic.KeyValuePair_2OfString_String)` |
     * | Nullable | `false` |
     */
    items: KeyValuePair_2OfStringString[];
    /**
     *
     * OData Attributes:
     * |Attribute Name | Attribute Value |
     * | --- | ---|
     * | Name | `isPersistent` |
     * | Type | `Edm.Boolean` |
     * | Nullable | `false` |
     */
    isPersistent: boolean;
    /**
     *
     * OData Attributes:
     * |Attribute Name | Attribute Value |
     * | --- | ---|
     * | Name | `redirectUri` |
     * | Type | `Edm.String` |
     */
    redirectUri: string | null;
    /**
     *
     * OData Attributes:
     * |Attribute Name | Attribute Value |
     * | --- | ---|
     * | Name | `issuedUtc` |
     * | Type | `Edm.DateTimeOffset` |
     */
    issuedUtc: string | null;
    /**
     *
     * OData Attributes:
     * |Attribute Name | Attribute Value |
     * | --- | ---|
     * | Name | `expiresUtc` |
     * | Type | `Edm.DateTimeOffset` |
     */
    expiresUtc: string | null;
    /**
     *
     * OData Attributes:
     * |Attribute Name | Attribute Value |
     * | --- | ---|
     * | Name | `allowRefresh` |
     * | Type | `Edm.Boolean` |
     */
    allowRefresh: boolean | null;
}

export interface EditableAuthenticationProperties extends Pick<AuthenticationProperties, "isPersistent">, Partial<Pick<AuthenticationProperties, "redirectUri" | "issuedUtc" | "expiresUtc" | "allowRefresh">> {
    items: EditableKeyValuePair_2OfStringString[];
}

export interface KeyValuePair_2OfStringString {
}

export interface EditableKeyValuePair_2OfStringString {
}

export interface EntityTagHeaderValue {
}

export interface EditableEntityTagHeaderValue {
}

export interface IFileProvider {
}

export interface EditableIFileProvider {
}

export interface IOutputFormatter {
}

export interface EditableIOutputFormatter {
}

export interface MemberInfo {
    /**
     *
     * OData Attributes:
     * |Attribute Name | Attribute Value |
     * | --- | ---|
     * | Name | `customAttributes` |
     * | Type | `Collection(System.Reflection.CustomAttributeData)` |
     * | Nullable | `false` |
     */
    customAttributes: CustomAttributeData[];
}

export interface EditableMemberInfo {
    customAttributes: EditableCustomAttributeData[];
}

export interface CustomAttributeData {
    /**
     *
     * OData Attributes:
     * |Attribute Name | Attribute Value |
     * | --- | ---|
     * | Name | `constructorArguments` |
     * | Type | `Collection(System.Reflection.CustomAttributeTypedArgument)` |
     */
    constructorArguments: CustomAttributeTypedArgument[];
    /**
     *
     * OData Attributes:
     * |Attribute Name | Attribute Value |
     * | --- | ---|
     * | Name | `namedArguments` |
     * | Type | `Collection(System.Reflection.CustomAttributeNamedArgument)` |
     */
    namedArguments: CustomAttributeNamedArgument[];
}

export interface EditableCustomAttributeData {
    constructorArguments?: EditableCustomAttributeTypedArgument[];
    namedArguments?: EditableCustomAttributeNamedArgument[];
}

export interface CustomAttributeTypedArgument {
}

export interface EditableCustomAttributeTypedArgument {
}

export interface CustomAttributeNamedArgument {
}

export interface EditableCustomAttributeNamedArgument {
}

export interface Type extends MemberInfo {
    /**
     *
     * OData Attributes:
     * |Attribute Name | Attribute Value |
     * | --- | ---|
     * | Name | `genericTypeArguments` |
     * | Type | `Collection(System.Type)` |
     * | Nullable | `false` |
     */
    genericTypeArguments: Type[];
}

export interface EditableType {
    customAttributes: EditableCustomAttributeData[];
    genericTypeArguments: EditableType[];
}

export interface TypeInfo extends Type {
    /**
     *
     * OData Attributes:
     * |Attribute Name | Attribute Value |
     * | --- | ---|
     * | Name | `genericTypeParameters` |
     * | Type | `Collection(System.Type)` |
     * | Nullable | `false` |
     */
    genericTypeParameters: Type[];
    /**
     *
     * OData Attributes:
     * |Attribute Name | Attribute Value |
     * | --- | ---|
     * | Name | `declaredConstructors` |
     * | Type | `Collection(System.Reflection.ConstructorInfo)` |
     */
    declaredConstructors: ConstructorInfo[];
    /**
     *
     * OData Attributes:
     * |Attribute Name | Attribute Value |
     * | --- | ---|
     * | Name | `declaredEvents` |
     * | Type | `Collection(System.Reflection.EventInfo)` |
     */
    declaredEvents: EventInfo[];
    /**
     *
     * OData Attributes:
     * |Attribute Name | Attribute Value |
     * | --- | ---|
     * | Name | `declaredFields` |
     * | Type | `Collection(System.Reflection.FieldInfo)` |
     */
    declaredFields: FieldInfo[];
    /**
     *
     * OData Attributes:
     * |Attribute Name | Attribute Value |
     * | --- | ---|
     * | Name | `declaredMembers` |
     * | Type | `Collection(System.Reflection.MemberInfo)` |
     */
    declaredMembers: MemberInfo[];
    /**
     *
     * OData Attributes:
     * |Attribute Name | Attribute Value |
     * | --- | ---|
     * | Name | `declaredMethods` |
     * | Type | `Collection(System.Reflection.MethodInfo)` |
     */
    declaredMethods: MethodInfo[];
    /**
     *
     * OData Attributes:
     * |Attribute Name | Attribute Value |
     * | --- | ---|
     * | Name | `declaredNestedTypes` |
     * | Type | `Collection(System.Reflection.TypeInfo)` |
     * | Nullable | `false` |
     */
    declaredNestedTypes: TypeInfo[];
    /**
     *
     * OData Attributes:
     * |Attribute Name | Attribute Value |
     * | --- | ---|
     * | Name | `declaredProperties` |
     * | Type | `Collection(System.Reflection.PropertyInfo)` |
     */
    declaredProperties: PropertyInfo[];
    /**
     *
     * OData Attributes:
     * |Attribute Name | Attribute Value |
     * | --- | ---|
     * | Name | `implementedInterfaces` |
     * | Type | `Collection(System.Type)` |
     * | Nullable | `false` |
     */
    implementedInterfaces: Type[];
}

export interface EditableTypeInfo {
    customAttributes: EditableCustomAttributeData[];
    genericTypeArguments: EditableType[];
    genericTypeParameters: EditableType[];
    declaredConstructors?: EditableConstructorInfo[];
    declaredEvents?: EditableEventInfo[];
    declaredFields?: EditableFieldInfo[];
    declaredMembers?: EditableMemberInfo[];
    declaredMethods?: EditableMethodInfo[];
    declaredNestedTypes: EditableTypeInfo[];
    declaredProperties?: EditablePropertyInfo[];
    implementedInterfaces: EditableType[];
}

export interface MethodBase extends MemberInfo {
}

export interface EditableMethodBase {
    customAttributes: EditableCustomAttributeData[];
}

export interface ConstructorInfo extends MethodBase {
}

export interface EditableConstructorInfo {
    customAttributes: EditableCustomAttributeData[];
}

export interface EventInfo extends MemberInfo {
}

export interface EditableEventInfo {
    customAttributes: EditableCustomAttributeData[];
}

export interface FieldInfo extends MemberInfo {
}

export interface EditableFieldInfo {
    customAttributes: EditableCustomAttributeData[];
}

export interface PropertyInfo extends MemberInfo {
}

export interface EditablePropertyInfo {
    customAttributes: EditableCustomAttributeData[];
}

export interface MethodInfo extends MethodBase {
}

export interface EditableMethodInfo {
    customAttributes: EditableCustomAttributeData[];
}

export interface TypeDelegator extends TypeInfo {
}

export interface EditableTypeDelegator {
    customAttributes: EditableCustomAttributeData[];
    genericTypeArguments: EditableType[];
    genericTypeParameters: EditableType[];
    declaredConstructors?: EditableConstructorInfo[];
    declaredEvents?: EditableEventInfo[];
    declaredFields?: EditableFieldInfo[];
    declaredMembers?: EditableMemberInfo[];
    declaredMethods?: EditableMethodInfo[];
    declaredNestedTypes: EditableTypeInfo[];
    declaredProperties?: EditablePropertyInfo[];
    implementedInterfaces: EditableType[];
}

export interface TypeBuilder extends TypeInfo {
}

export interface EditableTypeBuilder {
    customAttributes: EditableCustomAttributeData[];
    genericTypeArguments: EditableType[];
    genericTypeParameters: EditableType[];
    declaredConstructors?: EditableConstructorInfo[];
    declaredEvents?: EditableEventInfo[];
    declaredFields?: EditableFieldInfo[];
    declaredMembers?: EditableMemberInfo[];
    declaredMethods?: EditableMethodInfo[];
    declaredNestedTypes: EditableTypeInfo[];
    declaredProperties?: EditablePropertyInfo[];
    implementedInterfaces: EditableType[];
}

export interface ConstructorBuilder extends ConstructorInfo {
    /**
     *
     * OData Attributes:
     * |Attribute Name | Attribute Value |
     * | --- | ---|
     * | Name | `initLocals` |
     * | Type | `Edm.Boolean` |
     * | Nullable | `false` |
     */
    initLocals: boolean;
}

export interface EditableConstructorBuilder extends Pick<ConstructorBuilder, "initLocals"> {
    customAttributes: EditableCustomAttributeData[];
}

export interface FieldBuilder extends FieldInfo {
}

export interface EditableFieldBuilder {
    customAttributes: EditableCustomAttributeData[];
}

export interface PropertyBuilder extends PropertyInfo {
}

export interface EditablePropertyBuilder {
    customAttributes: EditableCustomAttributeData[];
}

export interface MethodBuilder extends MethodInfo {
    /**
     *
     * OData Attributes:
     * |Attribute Name | Attribute Value |
     * | --- | ---|
     * | Name | `initLocals` |
     * | Type | `Edm.Boolean` |
     * | Nullable | `false` |
     */
    initLocals: boolean;
}

export interface EditableMethodBuilder extends Pick<MethodBuilder, "initLocals"> {
    customAttributes: EditableCustomAttributeData[];
}

export interface DynamicMethod extends MethodInfo {
    /**
     *
     * OData Attributes:
     * |Attribute Name | Attribute Value |
     * | --- | ---|
     * | Name | `initLocals` |
     * | Type | `Edm.Boolean` |
     * | Nullable | `false` |
     */
    initLocals: boolean;
}

export interface EditableDynamicMethod extends Pick<DynamicMethod, "initLocals"> {
    customAttributes: EditableCustomAttributeData[];
}

export interface GenericTypeParameterBuilder extends TypeInfo {
}

export interface EditableGenericTypeParameterBuilder {
    customAttributes: EditableCustomAttributeData[];
    genericTypeArguments: EditableType[];
    genericTypeParameters: EditableType[];
    declaredConstructors?: EditableConstructorInfo[];
    declaredEvents?: EditableEventInfo[];
    declaredFields?: EditableFieldInfo[];
    declaredMembers?: EditableMemberInfo[];
    declaredMethods?: EditableMethodInfo[];
    declaredNestedTypes: EditableTypeInfo[];
    declaredProperties?: EditablePropertyInfo[];
    implementedInterfaces: EditableType[];
}

export interface EnumBuilder extends TypeInfo {
}

export interface EditableEnumBuilder {
    customAttributes: EditableCustomAttributeData[];
    genericTypeArguments: EditableType[];
    genericTypeParameters: EditableType[];
    declaredConstructors?: EditableConstructorInfo[];
    declaredEvents?: EditableEventInfo[];
    declaredFields?: EditableFieldInfo[];
    declaredMembers?: EditableMemberInfo[];
    declaredMethods?: EditableMethodInfo[];
    declaredNestedTypes: EditableTypeInfo[];
    declaredProperties?: EditablePropertyInfo[];
    implementedInterfaces: EditableType[];
}

export interface ComAwareEventInfo extends EventInfo {
}

export interface EditableComAwareEventInfo {
    customAttributes: EditableCustomAttributeData[];
}

export interface CreatedODataResult_1OfT extends ObjectResult {
}

export interface EditableCreatedODataResult_1OfT extends Partial<Pick<CreatedODataResult_1OfT, "value" | "contentTypes" | "statusCode">> {
    formatters?: EditableIOutputFormatter[];
    declaredType?: EditableType | null;
}

export interface UpdatedODataResult_1OfT extends ObjectResult {
}

export interface EditableUpdatedODataResult_1OfT extends Partial<Pick<UpdatedODataResult_1OfT, "value" | "contentTypes" | "statusCode">> {
    formatters?: EditableIOutputFormatter[];
    declaredType?: EditableType | null;
}

export interface BadRequestODataResult extends BadRequestResult {
}

export interface EditableBadRequestODataResult {
}

export interface ConflictODataResult extends ConflictResult {
}

export interface EditableConflictODataResult {
}

export interface NotFoundODataResult extends NotFoundResult {
}

export interface EditableNotFoundODataResult {
}

export interface UnauthorizedODataResult extends UnauthorizedResult {
}

export interface EditableUnauthorizedODataResult {
}

export interface UnprocessableEntityODataResult extends UnprocessableEntityResult {
}

export interface EditableUnprocessableEntityODataResult {
}

export interface ODataErrorResult extends ActionResult {
}

export interface EditableODataErrorResult {
}

export interface ClaimsPrincipal {
    /**
     *
     * OData Attributes:
     * |Attribute Name | Attribute Value |
     * | --- | ---|
     * | Name | `claims` |
     * | Type | `Collection(System.Security.Claims.Claim)` |
     */
    claims: Claim[];
    /**
     *
     * OData Attributes:
     * |Attribute Name | Attribute Value |
     * | --- | ---|
     * | Name | `identities` |
     * | Type | `Collection(System.Security.Claims.ClaimsIdentity)` |
     */
    identities: ClaimsIdentity[];
}

export interface EditableClaimsPrincipal {
    claims?: EditableClaim[];
    identities?: EditableClaimsIdentity[];
}

export interface Claim {
    /**
     *
     * OData Attributes:
     * |Attribute Name | Attribute Value |
     * | --- | ---|
     * | Name | `properties` |
     * | Type | `Collection(System.Collections.Generic.KeyValuePair_2OfString_String)` |
     * | Nullable | `false` |
     */
    properties: KeyValuePair_2OfStringString[];
}

export interface EditableClaim {
    properties: EditableKeyValuePair_2OfStringString[];
}

export interface ClaimsIdentity {
    /**
     *
     * OData Attributes:
     * |Attribute Name | Attribute Value |
     * | --- | ---|
     * | Name | `actor` |
     * | Type | `System.Security.Claims.ClaimsIdentity` |
     */
    actor: ClaimsIdentity | null;
    /**
     *
     * OData Attributes:
     * |Attribute Name | Attribute Value |
     * | --- | ---|
     * | Name | `bootstrapContext` |
     * | Type | `Edm.Untyped` |
     */
    bootstrapContext: string | null;
    /**
     *
     * OData Attributes:
     * |Attribute Name | Attribute Value |
     * | --- | ---|
     * | Name | `claims` |
     * | Type | `Collection(System.Security.Claims.Claim)` |
     * | Nullable | `false` |
     */
    claims: Claim[];
    /**
     *
     * OData Attributes:
     * |Attribute Name | Attribute Value |
     * | --- | ---|
     * | Name | `label` |
     * | Type | `Edm.String` |
     */
    label: string | null;
}

export interface EditableClaimsIdentity extends Partial<Pick<ClaimsIdentity, "bootstrapContext" | "label">> {
    actor?: EditableClaimsIdentity | null;
    claims: EditableClaim[];
}

export interface GenericIdentity extends ClaimsIdentity {
}

export interface EditableGenericIdentity extends Partial<Pick<GenericIdentity, "bootstrapContext" | "label">> {
    actor?: EditableClaimsIdentity | null;
    claims: EditableClaim[];
}

export interface GenericPrincipal extends ClaimsPrincipal {
}

export interface EditableGenericPrincipal {
    claims?: EditableClaim[];
    identities?: EditableClaimsIdentity[];
}
